import axios from "axios";
import { Firmware, FirmwareType, FirmwareUpdateRule } from "./FirmwareTypes";

export function fetchFirmware(type: FirmwareType) {
    return new Promise<[Firmware]>((resolve, reject) => {
        axios.get('/firmware/list/' + type).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error.response.status)
        });
    });
}

export function fetchAllFirmware() {
    return new Promise<[Firmware]>((resolve, reject) => {
        axios.get('/firmware/list').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error.response.status)
        });
    });
}

export function setFirmwareLatest(id: number, latest: boolean) {
    return new Promise<void>((resolve, reject) => {
        axios.put('/firmware/latest/' + id + "/" + latest).then(response => {
            resolve();
        }).catch(error => {
            reject(error.response.status)
        });
    });
}

export function setFirmwareMainstream(id: number, mainstream: boolean) {
    return new Promise<void>((resolve, reject) => {
        axios.put('/firmware/mainstream/' + id + "/" + mainstream).then(response => {
            resolve();
        }).catch(error => {
            reject(error.response.status)
        });
    });
}

export function downloadFirmware(type: string, version: string) {
    return new Promise<Blob>((resolve, reject) => {
        axios.get('https://api.bctrl.ch/api/v1/firmware/download/' + type + '/' + version, { responseType: 'blob' }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error.response.status)
        });
    });
}

export function deleteFirmware(id: number) {
    return new Promise<void>((resolve, reject) => {
        axios.delete('/firmware/delete/' + id).then(response => {
            resolve();
        }).catch(error => {
            reject(error.response.status)
        });
    });
}

export function fetchAllUpdateRules() {
    return new Promise<[FirmwareUpdateRule]>((resolve, reject) => {
        axios.get('/firmware/rule/list').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error.response.status)
        });
    });
}

export function insertFirmwareUpdateRule(rule: FirmwareUpdateRule) {
    return new Promise<void>((resolve, reject) => {
        axios.post('/firmware/rule/insert', rule).then(response => {
            resolve();
        }).catch(error => {
            reject(error.response.status)
        });
    });
}

export function updateFirmwareUpdateRule(rule: FirmwareUpdateRule) {
    return new Promise<void>((resolve, reject) => {
        axios.post('/firmware/rule/update', rule).then(response => {
            resolve();
        }).catch(error => {
            reject(error.response.status)
        });
    });
}

export function deleteFirmwareUpdateRule(id: string) {
    return new Promise<void>((resolve, reject) => {
        axios.delete('/firmware/rule/delete/' + id).then(response => {
            resolve();
        }).catch(error => {
            reject(error.response.status)
        });
    });
}

// export function setFirmwareUpdateRuleDenyUpdateFlag(id: string, active: boolean) {
//     return new Promise<void>((resolve, reject) => {
//         axios.put('/firmware/rule/' + id + "/deny/" + active).then(response => {
//             resolve();
//         }).catch(error => {
//             reject(error.response.status)
//         });
//     });
// }

// export function setFirmwareUpdateRuleTargetVersion(id: string, version: string) {
//     return new Promise<void>((resolve, reject) => {
//         axios.put('/firmware/rule/' + id + "/target/" + version).then(response => {
//             resolve();
//         }).catch(error => {
//             reject(error.response.status)
//         });
//     });
// }
