import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { I18nextProvider } from 'react-i18next';
import i18n_translations from "./i18n";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./auth_config";
import { history } from "./utils/history";
import App from './App';
import { getHost } from './utils/host';
import axios from 'axios';

const onRedirectCallback = (appState) => {
  history.replace(
    appState && appState.returnTo ? appState.returnTo : window.location.href
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

axios.defaults.baseURL = getHost() + "/api/v1";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n_translations}>
        <Auth0Provider {...providerConfig}>
          <App />
        </Auth0Provider>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);
