import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { IconContext } from "react-icons";
import { LuLayoutDashboard } from "react-icons/lu";
import { GrCloudSoftware } from "react-icons/gr";
import { Link } from "react-router-dom";
import { IsAuthenticationAndAuthorized } from "./auth/auth0-helper";

function Home() {
    return (
        <Container id="home">
            <Row>
                <Col>
                    <Card style={{ width: '18rem' }}>
                        <IconContext.Provider value={{ className: 'home-card-image' }}>
                            <Card.Img as={LuLayoutDashboard} variant="top" />
                        </IconContext.Provider>
                        <Card.Body>
                            <Card.Title>Fleet</Card.Title>
                            <Card.Text>
                                View the status of your fleet and manage individual systems.
                            </Card.Text>
                            <Link to="/fleet/dashboard">
                                <Button variant="outline-secondary">Fleet Dashboard</Button>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
                <IsAuthenticationAndAuthorized roles={['firmware_mgmt']}>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                            <IconContext.Provider value={{ className: 'home-card-image' }}>
                                <Card.Img as={GrCloudSoftware} variant="top" />
                            </IconContext.Provider>
                            <Card.Body>
                                <Card.Title>Fleet</Card.Title>
                                <Card.Text>
                                    Manage the lifecycle of all firmware releases.
                                </Card.Text>
                                <Link to="/firmware/dashboard">
                                    <Button variant="outline-secondary">Firmware Dashboard</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </IsAuthenticationAndAuthorized>
            </Row>
        </Container>
    )
}

export default Home;