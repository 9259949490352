import Container from 'react-bootstrap/Container';

function NotAuthorized() {
    return (
        <Container id="notfound">
            <Container className="notfound">
                <Container className="notfound-401">
                    <h1>Oops!</h1>
                    <h2>401 - Not Authorized</h2>
                </Container>
            </Container>
        </Container>
    );
}

export default NotAuthorized;
