import axios from "axios";
import { FleetOverview, SocVoltage, System, SystemDetail } from "./FleetTypes";

export function fetchFleetOverviewData() {
    return new Promise<FleetOverview>((resolve, reject) => {
        axios.get('/fleet/overview').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error.response.status)
        });
    });
}

export function fetchAllSystems() {
    return new Promise<System[]>((resolve, reject) => {
        axios.get('/fleet/systems').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error.response.status)
        });
    });
}


export function fetchSystemDetail(systemId: string) {
    return new Promise<SystemDetail>((resolve, reject) => {
        axios.get('/fleet/systems/' + systemId).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error.response.status)
        });
    });
}

export function querySocFromVoltage(systemId: string, voltage: number) {
    return new Promise<number>((resolve, reject) => {
        axios.get('/fleet/ocv/' + systemId + "/soc/" + voltage).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error.response.status)
        });
    });
}

export function queryVoltageFromSoc(systemId: string, soc: number) {
    return new Promise<SocVoltage>((resolve, reject) => {
        axios.get('/fleet/ocv/' + systemId + "/voltage/" + soc).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error.response.status)
        });
    });
}
