import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import Auth0Reducer from '../auth/Auth0Slice'
import FleetReducer from '../fleet/FleetSlice'
import SystemWsReducer from '../connection/SystemWsSlice'
import systemWsMiddleware from '../connection/SystemWsMiddleware';
import FirmwareReducer from '../firmware/FirmwareSlice'

export const store = configureStore({
    reducer: {
        auth0: Auth0Reducer,
        fleet: FleetReducer,
        systemWs: SystemWsReducer,
        firmware: FirmwareReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat([systemWsMiddleware])
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
