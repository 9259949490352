import React, { useEffect } from 'react';
import i18n from "i18next";
import useInterval from '../hooks/interval';
import { Container, Row, Col } from "react-bootstrap"
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { selectFleetOverview, queryFleetOverview } from "./FleetSlice";
import Stat from '../component/Stat';
import SystemsTable from './SystemsTable';

function FleetDashboard() {
    const dispatch = useAppDispatch();
    const overviewData = useAppSelector(selectFleetOverview);

    useInterval(() => {
        dispatch(queryFleetOverview());
    }, 2000);

    useEffect(() => {
        dispatch(queryFleetOverview());
    }, [dispatch])

    const charge = overviewData?.charge / 1000 || 0;
    const discharge = overviewData?.discharge / 1000 || 0;
    const total_capacity = (overviewData?.rated_capacity * 51.2) / 1000000 || 0;
    const capacity = (overviewData?.remaining_capacity * 51.2) / 1000000 || 0;
    const discharged = overviewData?.discharged / 1000000 || 0;
    const systemCount = overviewData?.systems || 0;
    const cells = overviewData?.modules * 16 || 0;
    const cycles = overviewData?.cycles || 0;

    return (
        <Container className="page">
            <Row>
                <Col>
                    <Stat title={i18n.t('fleet.dashboard.boxes.charging')} value={charge.toFixed(2)} unit="Kw" />
                </Col>
                <Col>
                    <Stat title={i18n.t('fleet.dashboard.boxes.discharging')} value={discharge.toFixed(2)} unit="Kw" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Stat title={i18n.t('fleet.dashboard.boxes.total_capacity')} value={total_capacity.toFixed(3)} unit="MWh" />
                </Col>
                <Col>
                    <Stat title={i18n.t('fleet.dashboard.boxes.free_capacity')} value={capacity.toFixed(3)} unit="MWh" />
                </Col>
                <Col>
                    <Stat title={i18n.t('fleet.dashboard.boxes.discharged')} value={discharged.toFixed(3)} unit="MWh" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Stat title={i18n.t('fleet.dashboard.boxes.systems')} value={systemCount} unit="" />
                </Col>
                <Col>
                    <Stat title={i18n.t('fleet.dashboard.boxes.battery_cells')} value={cells} unit="" />
                </Col>
                <Col>
                    <Stat title={i18n.t('fleet.dashboard.boxes.cycles')} value={cycles} unit="" />
                </Col>
            </Row>
            <Row>
                <Col id="systems-delimiter">
                    &nbsp;
                </Col>
            </Row>
            <Row>
                <SystemsTable id="system-table" />
            </Row>
        </Container >
    )
}

export default FleetDashboard;
