import i18n from "i18next";
import Image from "react-bootstrap/Image";
import logo from '../assets/logo_large.svg';
import { Link } from "react-router-dom";
import { FiHome } from "react-icons/fi";
import { FaArrowRightToBracket } from "react-icons/fa6";
import { LuLayoutDashboard } from "react-icons/lu";
import { useAuth0 } from "@auth0/auth0-react";
import { GrCloudSoftware } from "react-icons/gr";
import { MdOutlineRule } from "react-icons/md";
import { ListGroup } from "react-bootstrap";
import { IsAuthenticationAndAuthorized } from "../auth/auth0-helper";

const itemClasses = "list-group-item list-group-item-action sidebar-reactive"

function SideBar(props) {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const hideSidebar = props.hideSidebar;
    return (
        <div id="service-sidebar" className="no-print">
            <div className="container-fluid" id="sidebar-header-section">
                <Image src={logo} className="sidebar-logo" id="header-logo" alt={i18n.t("brand.name")} />
            </div>
            <div id="sidebar-menu">
                {!isAuthenticated &&
                    <ListGroup.Item as={Link} to="/" className={itemClasses} onClick={() => loginWithRedirect()}><span><FaArrowRightToBracket /></span>&nbsp;{i18n.t('sidebar.menu.login')}</ListGroup.Item>
                }
                <ListGroup.Item as={Link} to="/" className={itemClasses} onClick={() => hideSidebar()}><span><FiHome /></span>&nbsp;{i18n.t('menu.home')}</ListGroup.Item>
                <ListGroup.Item as={Link} to="/fleet/dashboard" className={itemClasses} onClick={() => hideSidebar()}><span><LuLayoutDashboard /></span>&nbsp;{i18n.t('menu.fleet.dashboard')}</ListGroup.Item>
                <IsAuthenticationAndAuthorized roles={['firmware_mgmt']}>
                    <ListGroup.Item as={Link} to="/firmware/dashboard" className={itemClasses} onClick={() => hideSidebar()}><span><GrCloudSoftware /></span>&nbsp;{i18n.t('menu.firmware.dashboard')}</ListGroup.Item>
                    <ListGroup.Item as={Link} to="/firmware/updaterule" className={itemClasses} onClick={() => hideSidebar()}><span><MdOutlineRule /></span>&nbsp;{i18n.t('menu.firmware.updaterule')}</ListGroup.Item>
                </IsAuthenticationAndAuthorized>
            </div>
        </div >
    );
}

export default SideBar;
