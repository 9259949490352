import { useState } from 'react'
import clsx from 'clsx'
import { Outlet } from 'react-router-dom';
import TopBar from './TopBar';
import SideBar from './SideBar';

function Root(props) {

    const [toggleSidebarState, setToggleSidebarState] = useState();

    return (
        <div className={clsx(props.theme, 'd-flex', toggleSidebarState && 'toggled')} id="wrapper">
            <SideBar hideSidebar={() => setToggleSidebarState(false)} />
            <div id="page-content-wrapper">
                <TopBar toggleSidebar={() => setToggleSidebarState(!toggleSidebarState)} expand={!toggleSidebarState} />
                <div className="d-flex flex-column align-items-center" >
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Root;
