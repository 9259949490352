import { Middleware } from 'redux'
import { systemWsActions } from './SystemWsSlice';
import { getWsHost } from "../utils/host";
import { SystemWsMessage } from './SystemWsTypes';
import { getApiToken } from '../auth/auth0-helper';
import { fleetActions } from '../fleet/FleetSlice';

const systemWsMiddleware: Middleware = store => {
    let socket: WebSocket;

    return next => action => {
        const isConnectionEstablished = socket !== null

        if (systemWsActions.connect.match(action)) {
            const clientId = action.payload?.toUpperCase();
            socket?.close();
            socket = new WebSocket(getWsHost() + "/api/v1/fleet/systems/" + clientId + "/ws");
            socket.onopen = () => {
                store.dispatch(systemWsActions.connectionEstablished(clientId));
            }
            socket.onclose = () => {
                store.dispatch(systemWsActions.stoppingConnection());
            }
            socket.onmessage = (event) => {
                var msg;
                try {
                    msg = JSON.parse(event.data);
                } catch (error) {
                    console.log(error);
                    return;
                }
                if (!store.getState().systemWs.isAuthenticated) {
                    if (msg.hasOwnProperty('authenticated') && msg["authenticated"]) {
                        store.dispatch(systemWsActions.connectionAuthenticated());
                    }
                    return;
                }
                switch (msg?.type) {
                    case 0:
                        store.dispatch(fleetActions.systemRestartComplete());
                        store.dispatch(systemWsActions.onStatusReceived(msg));
                        break;
                    case 3:
                        if (msg.cmd === 5) {
                            store.dispatch(systemWsActions.onConfigurationReceived(msg));
                        }
                        if (msg.cmd === 3) {
                            store.dispatch(systemWsActions.submitMessage(SystemWsMessage.CMD_READ_ALL_CONFIGURATIONS));
                        }
                        break;
                    default:
                        break;
                }
                store.dispatch(systemWsActions.onMessageReceived(event.data));
            }
            socket.onerror = (event) => {
                // TODO: implement error handling
                console.log(event);
            }
        }
        if (systemWsActions.connectionEstablished.match(action)) {
            getApiToken().then((token: string) => {
                socket.send(token);
            });
        }
        if (systemWsActions.disconnect.match(action)) {
            if (isConnectionEstablished) {
                socket?.close();
            }
        }
        if (systemWsActions.submitMessage.match(action)) {
            if (isConnectionEstablished && store.getState().systemWs.isAuthenticated) {
                socket.send(action.payload);
            }
        }
        next(action);
    }
}

export default systemWsMiddleware;
