import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import i18n from 'i18next'
import { withTranslation } from "react-i18next";
import InputGroup from 'react-bootstrap/InputGroup';
import { querySocFromVoltage, queryVoltageFromSoc } from './FleetApi';
import { querySocVoltageRange, selectSocVoltageRange } from './FleetSlice';
import clsx from "clsx";

export interface DodConfigurationItemContainerProps {
    systemId: string;
    soc: number;
    mdcv: number;
    mccv: number;
    callback: (minVoltage: number, minVoltageModified: boolean, maxVoltage: number, maxVoltageModified: boolean) => any;
}

function Dod({ systemId, soc, mdcv, mccv, callback }: DodConfigurationItemContainerProps) {
    const dispatch = useAppDispatch();
    const voltageRange = useAppSelector(selectSocVoltageRange);

    const [minSocOriginal, setMinSocOriginal] = useState<number>(0);
    const [maxSocOriginal, setMaxSocOriginal] = useState<number>(0);
    const [minVoltageOriginal, setMinVoltageOriginal] = useState<number>(0);
    const [maxVoltageOriginal, setMaxVoltageOriginal] = useState<number>(0);

    const [minSoc, setMinSoc] = useState<number>(0);
    const [maxSoc, setMaxSoc] = useState<number>(0);
    const [minVoltage, setMinVoltage] = useState<number>(0);
    const [maxVoltage, setMaxVoltage] = useState<number>(0);

    const [minSocModified, setMinSocModified] = useState<boolean>(false);
    const [maxSocModified, setMaxSocModified] = useState<boolean>(false);
    const [minVoltageModified, setMinVoltageModified] = useState<boolean>(false);
    const [maxVoltageModified, setMaxVoltageModified] = useState<boolean>(false);

    const [minSocInvalid, setMinSocInvalid] = useState<boolean>(false);
    const [maxSocInvalid, setMaxSocInvalid] = useState<boolean>(false);
    const [minVoltageInvalid, setMinVoltageInvalid] = useState<boolean>(false);
    const [maxVoltageInvalid, setMaxVoltageInvalid] = useState<boolean>(false);

    useEffect(() => {
        dispatch(querySocVoltageRange({ systemId: systemId, soc: soc }));
        setMinVoltageInvalid(false);
        setMinVoltageOriginal(mdcv || 0);
        setMinVoltage(mdcv || 0);
        setMaxVoltageInvalid(false);
        setMaxVoltageOriginal(mccv || 0);
        setMaxVoltage(mccv || 0);

        querySocFromVoltage(systemId, mdcv || 0).then(response => {
            setMinSocInvalid(false);
            setMinSocOriginal(Math.round(response / 100));
            setMinSoc(Math.round(response / 100));
        });
        querySocFromVoltage(systemId, mccv || 0).then(response => {
            setMaxSocInvalid(false);
            setMaxSocOriginal(Math.round(response / 100));
            setMaxSoc(Math.round(response / 100));
        });
    }, [dispatch, mccv, mdcv, soc, systemId]);

    useEffect(() => {
        setMinSocInvalid(false);
        setMaxSocInvalid(false);
        setMinVoltageInvalid(false);
        setMaxVoltageInvalid(false);
        // min soc
        if (isNaN(minSoc)) {
            setMinSocInvalid(true);
        }
        if (minSoc < 0 || minSoc > 100) {
            setMinSocInvalid(true);
        }
        if (minSoc > maxSoc) {
            setMinSocInvalid(true);
        }
        // max soc
        if (isNaN(maxSoc)) {
            setMaxSocInvalid(true);
        }
        if (maxSoc < 0 || maxSoc > 100) {
            setMaxSocInvalid(true);
        }
        if (maxSoc < minSoc) {
            setMaxSocInvalid(true);
        }
        // min voltage
        if (isNaN(minVoltage)) {
            setMinVoltageInvalid(true);
        }
        if (minVoltage < ((voltageRange && voltageRange?.min) || 0)) {
            setMinVoltageInvalid(true);
        }
        if (minVoltage > ((voltageRange && voltageRange?.max) || 5000)) {
            setMinVoltageInvalid(true);
        }
        if (minVoltage > maxVoltage) {
            setMinVoltageInvalid(true);
        }
        // max voltage
        if (isNaN(maxVoltage)) {
            setMaxVoltageInvalid(true);
        }
        if (maxVoltage < ((voltageRange && voltageRange?.min) || 0)) {
            setMaxVoltageInvalid(true);
        }
        if (maxVoltage > ((voltageRange && voltageRange?.max) || 5000)) {
            setMaxVoltageInvalid(true);
        }
        if (maxVoltage < minVoltage) {
            setMaxVoltageInvalid(true);
        }
        // detect changes
        setMinSocModified(minSoc !== minSocOriginal);
        setMaxSocModified(maxSoc !== maxSocOriginal);
        setMinVoltageModified(minVoltage !== minVoltageOriginal);
        setMaxVoltageModified(maxVoltage !== maxVoltageOriginal);
    }, [voltageRange, minSoc, maxSoc, minVoltage, maxVoltage, minSocOriginal, maxSocOriginal, minVoltageOriginal, maxVoltageOriginal, setMinSocInvalid, setMaxSocInvalid, setMinVoltageInvalid, setMaxVoltageInvalid, setMinSocModified, setMaxSocModified, setMinVoltageModified, setMaxVoltageModified]);

    const enum DodConfigurationItem {
        MIN_SOC = "minSoc",
        MAX_SOC = "maxSoc",
        MIN_VOLTAGE = "minVoltage",
        MAX_VOLTAGE = "maxVoltage"
    }

    const resetValues = () => {
        setMinSoc(minSocOriginal);
        setMaxSoc(maxSocOriginal);
        setMinVoltage(minVoltageOriginal);
        setMaxVoltage(maxVoltageOriginal);
    };

    const handleChange = (item: DodConfigurationItem, value: number) => {
        switch (item) {
            case DodConfigurationItem.MIN_SOC:
                setMinSoc(value);
                if (!isNaN(value)) {
                    queryVoltageFromSoc(systemId, value).then(response => {
                        setMinVoltage(response.value);
                    });
                }
                break;
            case DodConfigurationItem.MAX_SOC:
                setMaxSoc(value);
                if (!isNaN(value)) {
                    queryVoltageFromSoc(systemId, value).then(response => {
                        setMaxVoltage(response.value);
                    });
                }
                break;
            case DodConfigurationItem.MIN_VOLTAGE:
                setMinVoltage(value);
                if (!isNaN(value)) {
                    querySocFromVoltage(systemId, value).then(response => {
                        setMinSoc(Math.round(response / 100));
                    });
                }
                break;
            case DodConfigurationItem.MAX_VOLTAGE:
                setMaxVoltage(value);
                if (!isNaN(value)) {
                    querySocFromVoltage(systemId, value).then(response => {
                        setMaxSoc(Math.round(response / 100));
                    });
                }
                break;
            default:
                break;
        }
    };

    const handleSubmit = () => {
        if (minSocInvalid || maxSocInvalid || minVoltageInvalid || maxVoltageInvalid) {
            return;
        }
        callback(minVoltage, minVoltageModified, maxVoltage, maxVoltageModified);
    };

    return (
        <Container id="dod-container">
            <Row>
                <Col>
                    <span id="dod-title">{i18n.t("fleet.system-interaction.dod.title")}</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <span id="dod-description">{i18n.t("fleet.system-interaction.dod.description")}</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label>{i18n.t("fleet.system-interaction.dod.soc.min.label")}</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="number"
                            onChange={e => handleChange(DodConfigurationItem.MIN_SOC, parseInt(e.target.value))}
                            isInvalid={minSocInvalid}
                            isValid={false}
                            value={isNaN(minSoc) ? "" : minSoc}
                        />
                        <InputGroup.Text className='dod-input-group-text'>%</InputGroup.Text>
                        <Form.Control.Feedback type="invalid">
                            {i18n.t("fleet.system-interaction.dod.validation.min", { min: 0, max: maxSoc })}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col>
                    <Form.Label>{i18n.t("fleet.system-interaction.dod.range.label")}</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="number"
                            readOnly
                            value={(isNaN(maxSoc) ? 0 : maxSoc) - (isNaN(minSoc) ? 0 : minSoc)}
                        />
                        <InputGroup.Text className='dod-input-group-text'>%</InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col>
                    <Form.Label>{i18n.t("fleet.system-interaction.dod.soc.max.label")}</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="number"
                            name="maxSoc"
                            onChange={e => handleChange(DodConfigurationItem.MAX_SOC, parseInt(e.target.value))}
                            isInvalid={maxSocInvalid}
                            isValid={false}
                            value={isNaN(maxSoc) ? "" : maxSoc}
                        />
                        <InputGroup.Text className='dod-input-group-text'>%</InputGroup.Text>
                        <Form.Control.Feedback type="invalid">
                            {i18n.t("fleet.system-interaction.dod.validation.max", { min: minSoc, max: 100 })}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="hxgn_mdcv">
                        <Form.Label>{i18n.t('fleet.system-interaction.dod.voltage.min.label')}</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                onChange={e => handleChange(DodConfigurationItem.MIN_VOLTAGE, parseInt(e.target.value))}
                                isInvalid={minVoltageInvalid}
                                isValid={false}
                                value={isNaN(minVoltage) ? "" : minVoltage}
                            />
                            <InputGroup.Text className='dod-input-group-text'>mV</InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                                {i18n.t("fleet.system-interaction.dod.validation.min", { min: voltageRange?.min, max: maxVoltage })}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col className="d-none d-sm-block" />
                <Col>
                    <Form.Group controlId="hxgn_mccv">
                        <Form.Label>{i18n.t('fleet.system-interaction.dod.voltage.max.label')}</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                onChange={e => handleChange(DodConfigurationItem.MAX_VOLTAGE, parseInt(e.target.value))}
                                isInvalid={maxVoltageInvalid}
                                isValid={false}
                                value={isNaN(maxVoltage) ? "" : maxVoltage}
                            />
                            <InputGroup.Text className='dod-input-group-text'>mV</InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                                {i18n.t("fleet.system-interaction.dod.validation.max", { min: minVoltage, max: voltageRange?.max })}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="outline-secondary"
                        id="dod-reset-button"
                        onClick={() => resetValues()}>
                        {i18n.t("fleet.system-interaction.dod.button.reset")}
                    </Button>
                    <Button variant="outline-secondary"
                        className={clsx((minSocModified || maxSocModified || minVoltageModified || maxVoltageModified) && "input-component-button-modified")}
                        disabled={minSocInvalid || maxSocInvalid || minVoltageInvalid || maxVoltageInvalid}
                        onClick={() => handleSubmit()}>
                        {i18n.t("fleet.system-interaction.dod.button.save")}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default withTranslation()(Dod);
