import { useEffect } from 'react';
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import NotAuthorized from '../theme/NotAuthorized';
import axios from 'axios';
import { useAppDispatch } from '../redux/hooks';
import { auth0Actions } from "./Auth0Slice";


export const CheckRole = (roles) => {
    const { user } = useAuth0();
    if (!roles) {
        return true;
    }
    if (user) {
        const userRoles = user['https://tt.modual.ch/v1/roles'];
        return userRoles && userRoles.some(role => roles.includes(role))
    }
    return false;
};

export const ProtectedRoute = ({ component, role, ...args }) => {
    if (!CheckRole(role)) {
        return <NotAuthorized />;
    }
    const Component = withAuthenticationRequired(component, args);
    return <Component />;
};

export const IsAuthenticationAndAuthorized = ({ roles, ...args }) => {
    const { user } = useAuth0();
    if ((!roles || roles.length <= 0) && user) {
        return args.children;
    }
    if (CheckRole(roles)) {
        return args.children;
    }
    return <></>;
}

const setAxiosTokenInterceptor = async (tokenGenerator, dispatch) => {
    axios.interceptors.request.use(async config => {
        if (tokenGenerator) {
            const token = await tokenGenerator();
            if (token) {

                config.headers['Authorization'] = `Bearer ${token}`;
            }
        }
        return config;
    });
    axios.interceptors.response.use(response => {
        dispatch(auth0Actions.backendLoginWorked())
        return response;
    }, error => {
        if (error?.response?.status === 401) {
            dispatch(auth0Actions.backendAuthenticationFailed())
        }
        return error;
    })
}

var tokenGenerator = null;

export const getApiToken = async () => {
    return tokenGenerator ? await tokenGenerator() : null;
}

export const useAuth0Token = () => {
    const dispatch = useAppDispatch();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        if (isAuthenticated) {
            tokenGenerator = getAccessTokenSilently;
            setAxiosTokenInterceptor(getAccessTokenSilently, dispatch);
        }
    }, [isAuthenticated, getAccessTokenSilently, dispatch]);
}
