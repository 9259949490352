import { useState, useEffect } from 'react';
import i18n from "i18next";
import useInterval from '../hooks/interval';
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { VscGraph } from "react-icons/vsc";
import { useNavigate } from 'react-router-dom';

import {
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import {
  rankItem,
} from '@tanstack/match-sorter-utils'
import { HealthType, System } from './FleetTypes'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { queryAllSystems, selectSystems } from './FleetSlice'

import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Moment from 'react-moment';
import { Col, Row as BRow } from 'react-bootstrap';
import DebouncedInput from '../component/DebouncedInput';
import Form from 'react-bootstrap/Form';


const versionParser = (version: string) => {
  if (version === null) {
    return 'n/a';
  }
  var tokens = version.split(",");
  if (tokens.length >= 1) {
    return tokens[0];
  }
  return 'n/a';
}

// this is not translated since it has to correspond to the values in the backend for easy search access 
// within the table.
const healthParser = (health: string) => {
  switch (health) {
    case HealthType.None:
      return 'n/a';
    case HealthType.PreCharge:
      return 'Pre-charge';
    case HealthType.Operational:
      return 'Operational';
    case HealthType.Degraded:
      return 'Degraded';
    case HealthType.OTA:
      return 'Update in Progress';
    case HealthType.Suspended:
      return 'Suspended';
    case HealthType.Fault:
      return 'Fault';
    case HealthType.Restart:
      return 'Restarting';
    case HealthType.Shutdown:
      return 'Shutdown';
    default:
    // no-op
  }
  // decagon
  switch (health) {
    case 'ERROR':
      return 'Fault';
    case 'OK':
      return 'Operational';
    case 'OTA':
      return 'Update in Progress';
    case 'OFFLINE':
      return 'Shutdown';
    case 'SHUTDOWN':
      return 'Shutdown';
    default:
    // no-op
  }
  return 'Unknown';
}

enum Health {
  SH_OPERATIONAL = 1,
  SH_PRE_CHARGE = 2,
  SH_NONE = 3,
  SH_RESTART = 4,
  SH_OTA = 5,
  SH_DEGRADED = 6,
  SH_SUSPENT = 7,
  SH_SHUTDOWN = 8,
  SH_FAULT = 9,
}

const healthStringtoEnum = (health: string): Health => {
  switch (health) {
    case 'SH_OPERATIONAL':
      return Health.SH_OPERATIONAL;
    case 'SH_PRE_CHARGE':
      return Health.SH_PRE_CHARGE;
    case 'SH_NONE':
      return Health.SH_NONE;
    case 'SH_RESTART':
      return Health.SH_RESTART;
    case 'SH_OTA':
      return Health.SH_OTA;
    case 'SH_DEGRADED':
      return Health.SH_DEGRADED;
    case 'SH_SUSPENT':
      return Health.SH_SUSPENT;
    case 'SH_FAULT':
      return Health.SH_FAULT;
    case 'SH_SHUTDOWN':
      return Health.SH_SHUTDOWN;
    default:
    // no-op
  }
  // decagon
  switch (health) {
    case 'ERROR':
      return Health.SH_FAULT;
    case 'OK':
      return Health.SH_OPERATIONAL;
    case 'OTA':
      return Health.SH_OTA;
    case 'OFFLINE':
      return Health.SH_SHUTDOWN;
    case 'SHUTDOWN':
      return Health.SH_SHUTDOWN;
    default:
    // no-op
  }
  return Health.SH_NONE;
}

const healthSort = (a: Row<System>, b: Row<System>, cId: string): number => {
  const healthA = healthStringtoEnum(a.original.health);
  const healthB = healthStringtoEnum(b.original.health);
  if (healthA < healthB) {
    return 1;
  }
  if (healthA > healthB) {
    return -1;
  }
  return 0;
}

const versionSort = (a: Row<System>, b: Row<System>, cId: string): number => {
  const versionA = versionParser(a.original.version).split(".");
  const versionB = versionParser(b.original.version).split(".");

  const vAMajor = Number(versionA[0]);
  const vAMinor = Number(versionA[1]);
  const vAPatch = Number(versionA[2]);

  const vBMajor = Number(versionB[0]);
  const vBMinor = Number(versionB[1]);
  const vBPatch = Number(versionB[2]);

  if (vAMajor > vBMajor) {
    return 1;
  }
  if (vAMajor < vBMajor) {
    return -1;
  }
  if (vAMinor > vBMinor) {
    return 1;
  }
  if (vAMinor < vBMinor) {
    return -1;
  }
  if (vAPatch > vBPatch) {
    return 1;
  }
  if (vAPatch < vBPatch) {
    return -1;
  }

  return 0;
}

const timeParser = (time: Date) => {
  if (time === undefined) {
    return 'n/a';
  }
  // const now = new Date();
  // const a_hour_ago = new Date(now.getTime() - (1000 * 60 * 60));
  // const two_hours_ago = new Date(now.getTime() - (1000 * 60 * 60 * 2));
  // const a_day_ago = new Date(now.getTime() - (1000 * 60 * 60 * 24));
  // console.log(time + " :: " + (time < a_hour_ago) + " :: " + now);
  // if (time < a_hour_ago) {
  //   return < Moment date={time} format="HH:mm:ss" />
  // }
  // if (time > a_hour_ago && time < two_hours_ago) {
  //   return '1 hour ago';
  // }
  // if (time >= two_hours_ago && time < a_day_ago) {
  //   return <Moment date={time} format="MM-DD HH:mm:ss" />
  // }
  // if (time >= a_day_ago) {
  //   return <Moment date={time} format="YYYY-MM-DD HH:mm:ss" />
  // }
  return <Moment date={time} format="YYYY-MM-DD HH:mm:ss" />
}

const isProductionSystemRegex = /^[A-Z]{1}-[A-Z0-9]{4}-[0-8]{1}.*$/

const isProductionSystem = (row: Row<System>, showAll: boolean): boolean => {
  if (showAll) {
    return true;
  }
  return isProductionSystemRegex.test(row.original.id)
}

const createGrafanaLink = (row: Row<System>): string => {
  const now = new Date();
  const from = new Date(row.original.last_seen).getTime() - (60 * 60 * 1000);
  const to = new Date(row.original.last_seen).getTime() + (60 * 60 * 1000);
  return "https://twiceenergy.grafana.net/d/fc4cf3b6-d7e2-49d4-9f9e-572bceca207d/device-beta?orgId=1&var-device=" + row.original.id.toUpperCase() + "&from=" + from + "&to=" + (to > now.getTime() ? "now" : to);
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value)
  addMeta({
    itemRank,
  })
  return itemRank.passed
}

const columnHelper = createColumnHelper<System>()

const columns = [
  columnHelper.accessor('id', {
    header: i18n.t('fleet.systemtable.header.id'),
    cell: info => info.getValue(),
    footer: info => info.column.id,
    sortDescFirst: false,
    enableMultiSort: true,
  }),
  columnHelper.accessor('health', {
    header: i18n.t('fleet.systemtable.header.health'),
    cell: info => healthParser(info.getValue()),
    footer: info => info.column.id,
    sortingFn: healthSort,
    sortDescFirst: false,
    enableMultiSort: true,
  }),
  columnHelper.accessor('version', {
    header: i18n.t('fleet.systemtable.header.version'),
    cell: info => versionParser(info.getValue()),
    footer: info => info.column.id,
    sortingFn: versionSort,
    sortDescFirst: false,
  }),
  columnHelper.accessor('last_seen', {
    header: i18n.t('fleet.systemtable.header.last_seen'),
    cell: info => timeParser(info.getValue()),
    footer: info => info.column.id,
  }),
  columnHelper.display({
    id: 'grafana',
    header: i18n.t('fleet.systemtable.header.grafana'),
    cell: info => <a href={createGrafanaLink(info.row)} target="blank" data-tooltip-id={"grafana-tooltip-" + info.row.original.id} data-tooltip-content={i18n.t('fleet.systemtable.tooltip.grafana_link')}><VscGraph className="system-table-grafana-icon" /></a>
  }),
]

function SystemTable(props: any) {
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectSystems);
  const navigate = useNavigate();

  const handleRowClick = (id: string) => {
    navigate('/fleet/system?id=' + id);
  }

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'health',
      desc: false,
    },
    {
      id: 'id',
      desc: false,
    },
  ]);

  const [globalFilter, setGlobalFilter] = useState('')

  const [showDevelopmentSystems, setShowDevelopmentSystems] = useState(false)

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
  })

  useInterval(() => {
    dispatch(queryAllSystems());
  }, 10000);

  useEffect(() => {
    dispatch(queryAllSystems());
  }, [dispatch])

  if (data === undefined) {
    return (
      <Container>
        <span>Aw Snap!</span>
      </Container>
    );
  }

  return (
    <Container {...props}>
      <BRow>
        <Col xs={4}>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={value => setGlobalFilter(String(value))}
            placeholder={i18n.t('fleet.systemtable.search.placeholder')}
          />
        </Col>
        <Col className="system-table-align-center-justify-left">
          <Form.Check
            type="checkbox"
            id="showAllSystems"
            label={i18n.t('fleet.systemtable.checkbox.showall.label')}
            onChange={value => setShowDevelopmentSystems(value.target.checked)}
            checked={showDevelopmentSystems}
          />
        </Col>
      </BRow>
      <BRow>
        <Table responsive id="fleet-system-table">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <FaSortUp />,
                            desc: <FaSortDown />,
                          }[header.column.getIsSorted() as string] ?? <FaSort />}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows
              .map(row => {
                if (!isProductionSystem(row, showDevelopmentSystems)) {
                  return null;
                }
                return (
                  <tr key={row.id}
                    className={'system-table-row-' + row.original.health.toLowerCase()}
                  >
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td key={cell.id} onClick={() => handleRowClick(row.original.id)}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </BRow>
    </Container>
  )
}

export default SystemTable;