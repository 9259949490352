import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import { FleetOverview, System, SocVoltage } from "./FleetTypes";
import { fetchAllSystems, fetchFleetOverviewData, queryVoltageFromSoc } from "./FleetApi";

export interface FleetState {
    overview: FleetOverview;
    systems: System[];
    socVoltageRange?: SocVoltage;
    systemRestartInProgress: boolean;
    systemShutdownInProgress: boolean;
}

const initialState: FleetState = {
    overview: {} as FleetOverview,
    systems: [] as System[],
    socVoltageRange: {} as SocVoltage,
    systemRestartInProgress: false,
    systemShutdownInProgress: false,
};

export const queryFleetOverview = createAsyncThunk(
    "fleet/overview",
    async () => {
        return await fetchFleetOverviewData();
    }
)

export const queryAllSystems = createAsyncThunk(
    "fleet/systems",
    async () => {
        return await fetchAllSystems();
    }
)

export const querySocVoltageRange = createAsyncThunk(
    "fleet/systems/soc_voltage",
    async ({ systemId, soc }: { systemId: string, soc: number }) => {
        return await queryVoltageFromSoc(systemId, soc);
    }
)

export const fleetSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        systemRestart: (state) => {
            state.systemRestartInProgress = true;
        },
        systemRestartComplete: (state) => {
            state.systemRestartInProgress = false;
        },
        systemShutdown: (state) => {
            state.systemShutdownInProgress = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(queryFleetOverview.fulfilled, (state, action) => {
                state.overview = action.payload;
            })
            .addCase(queryAllSystems.fulfilled, (state, action) => {
                state.systems = action.payload;
            })
            .addCase(querySocVoltageRange.fulfilled, (state, action) => {
                state.socVoltageRange = action.payload;
            })
    }
});


export const fleetActions = fleetSlice.actions;

export const selectFleetOverview = (state: RootState) => state.fleet.overview;
export const selectSystems = (state: RootState) => state.fleet.systems;
export const selectSocVoltageRange = (state: RootState) => state.fleet.socVoltageRange;
export const selectSystemRestartInProgress = (state: RootState) => state.fleet.systemRestartInProgress;
export const selectSystemShutdownInProgress = (state: RootState) => state.fleet.systemShutdownInProgress;

export default fleetSlice.reducer;
