import { useState, useEffect } from 'react';

import {
    createColumnHelper,
    FilterFn,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table'
import {
    rankItem,
} from '@tanstack/match-sorter-utils'

import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { FirmwareUpdateRule } from './FirmwareTypes'
import i18n from "i18next";
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { Col, Row as BRow } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FaSort, FaSortUp, FaSortDown, FaEdit } from "react-icons/fa";
import DebouncedInput from '../component/DebouncedInput';
import { queryAllUpdateRules, queryAllFirmware, selectAllUpdateRules } from './FirmwareSlice';
import { RiDeleteBin6Line } from "react-icons/ri";
import ModalButton from '../component/ModalButton';
import { FaPlus } from "react-icons/fa";
import { IconContext } from "react-icons";
import { queryAllSystems, selectSystems } from '../fleet/FleetSlice';
import AddOrEditUpdateRuleModal from './AddOrEditUpdateRuleModal';
import { deleteFirmwareUpdateRule, insertFirmwareUpdateRule, updateFirmwareUpdateRule } from './FirmwareApi';

export interface FirmwareUpdateRuleWithVersion {
    device: string;
    version: string;
    deny_update: boolean;
    target_version: string;
}

const versionParser = (version: string) => {
    if (version === null) {
        return 'n/a';
    }
    var tokens = version.split(",");
    if (tokens.length >= 1) {
        return tokens[0];
    }
    return 'n/a';
}


const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({
        itemRank,
    })
    return itemRank.passed
}

function FirmwareUpdateRuleTable(props: any) {
    const dispatch = useAppDispatch();
    const systems = useAppSelector(selectSystems);
    const data = useAppSelector(selectAllUpdateRules);

    const [globalFilter, setGlobalFilter] = useState('')

    useEffect(() => {
        dispatch(queryAllSystems())
        dispatch(queryAllUpdateRules());
        dispatch(queryAllFirmware())
    }, [dispatch])

    const onDeleteUpdateRule = (id: string) => {
        deleteFirmwareUpdateRule(id).then(() => {
            dispatch(queryAllUpdateRules());
        }).catch(error => {
            // TODO: handle error
            console.log(error);
        });
    }

    const onAdd = (deviceId: string, denyUpdate: boolean, targetVersion: string) => {
        insertFirmwareUpdateRule({ device: deviceId, deny_update: denyUpdate, target_version: targetVersion }).then(() => {
            dispatch(queryAllUpdateRules());
        }).catch(error => {
            // TODO: handle error
            console.log(error);
        });
    }

    const onEdit = (deviceId: string, denyUpdate: boolean, targetVersion: string) => {
        updateFirmwareUpdateRule({ device: deviceId, deny_update: denyUpdate, target_version: targetVersion }).then(() => {
            dispatch(queryAllUpdateRules());
        }).catch(error => {
            // TODO: handle error
            console.log(error);
        });
    }

    const columnHelper = createColumnHelper<FirmwareUpdateRule>()

    const columns = [
        columnHelper.accessor('device', {
            header: i18n.t('firmware.updaterule-table.header.device'),
            cell: info => info.getValue(),
            footer: info => info.column.id,
            sortDescFirst: false,
            enableMultiSort: true,
        }),
        columnHelper.display({
            id: 'version',
            header: i18n.t('firmware.updaterule-table.header.version'),
            cell: info => versionParser(systems.find(system => system.id === info.row.original.device)?.version || "n/a"),
        }),
        columnHelper.accessor('deny_update', {
            header: i18n.t('firmware.updaterule-table.header.denyUpdate'),
            cell: info => <Form.Check type="checkbox" checked={info.getValue()} readOnly={true} disabled />,
            footer: info => info.column.id,
            sortDescFirst: true,
            enableMultiSort: true,
        }),
        columnHelper.accessor('target_version', {
            header: i18n.t('firmware.updaterule-table.header.targetversion'),
            cell: info => info.getValue(),
            footer: info => info.column.id,
            sortDescFirst: false,
        }),
        columnHelper.display({
            id: 'edit',
            header: i18n.t('firmware.updaterule-table.header.edit'),
            cell: info => <AddOrEditUpdateRuleModal
                as={<FaEdit className="update-rule-clickable" />}
                deviceId={info.row.original.device}
                denyUpdate={info.row.original.deny_update}
                targetVersion={info.row.original.target_version}
                title={i18n.t('firmware.updaterule-table.edit.title', { system: info.row.original.device })}
                systemLabel={i18n.t('firmware.updaterule-table.edit.system')}
                denyUpdateLabel={i18n.t('firmware.updaterule-table.edit.denyupdate')}
                targetVersionLabel={i18n.t('firmware.updaterule-table.edit.targetversion')}
                cancelButtonLabel={i18n.t('firmware.updaterule-table.edit.button.cancel')}
                confirmButtonLabel={i18n.t('firmware.updaterule-table.edit.button.confirm')}
                okCallback={onEdit}
            />
        }),
        columnHelper.display({
            id: 'delete',
            header: i18n.t('firmware.updaterule-table.header.delete'),
            cell: info => <ModalButton
                as={<RiDeleteBin6Line className="update-rule-clickable" />}
                size="lg"
                variant="outline-secondary"
                className="modal-button-invisible-outline"
                modalTitle={i18n.t('firmware.updaterule-table.delete.modal.title', { system: info.row.original.device })}
                modalBody={i18n.t('firmware.updaterule-table.delete.modal.body')}
                closeButtonLabel={i18n.t('firmware.updaterule-table.delete.button.cancel')}
                okButtonLabel={i18n.t('firmware.updaterule-table.delete.button.confirm')}
                okCallback={() => onDeleteUpdateRule(info.row.original.device)}
            />
        })
    ]

    const [sorting, setSorting] = useState<SortingState>([
        {
            id: 'device',
            desc: false,
        }
    ]);

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            globalFilter,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
    })

    if (data === undefined) {
        return (
            <Container>
                <span>Aw Snap!</span>
            </Container>
        );
    }

    return (
        <Container {...props}>
            <BRow>
                <Col xs={4}>
                    <DebouncedInput
                        value={globalFilter ?? ''}
                        onChange={value => setGlobalFilter(String(value))}
                        placeholder={i18n.t('firmware.updaterule-table.search.placeholder')}
                    />
                </Col>
                <Col className="update-rule-table-align-center-justify-right">
                    <AddOrEditUpdateRuleModal
                        as={
                            <IconContext.Provider value={{ className: 'update-rule-table-add-button' }}>
                                <FaPlus />
                            </IconContext.Provider>
                        }
                        title={i18n.t('firmware.updaterule-table.add.title')}
                        systemLabel={i18n.t('firmware.updaterule-table.add.system')}
                        denyUpdateLabel={i18n.t('firmware.updaterule-table.add.denyupdate')}
                        targetVersionLabel={i18n.t('firmware.updaterule-table.add.targetversion')}
                        cancelButtonLabel={i18n.t('firmware.updaterule-table.add.button.cancel')}
                        confirmButtonLabel={i18n.t('firmware.updaterule-table.add.button.confirm')}
                        okCallback={onAdd}
                    />
                </Col>
            </BRow>
            <BRow>
                <Table responsive id="firmware-overview-table">
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {

                                    return (
                                        <th key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    {...{
                                                        className: header.column.getCanSort()
                                                            ? 'cursor-pointer select-none'
                                                            : '',
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {header.column.getCanSort() && ({
                                                        asc: <FaSortUp />,
                                                        desc: <FaSortDown />,
                                                    }[header.column.getIsSorted() as string] ?? <FaSort />)}
                                                </div>
                                            )}
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table
                            .getRowModel()
                            .rows
                            .map(row => {
                                return (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => {
                                            return (
                                                <td key={cell.id} {...cell.column.columnDef.meta}>
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </td>
                                            )
                                        })}
                                        {/* <td className='firmware-overview-clickable-cell'>
                                            <AddOrEditUpdateRuleModal
                                                as={<FaEdit />}
                                                deviceId={row.original.device}
                                                denyUpdate={row.original.deny_update}
                                                targetVersion={row.original.target_version}
                                                title={i18n.t('firmware.updaterule-table.edit.title', { system: row.original.device })}
                                                systemLabel={i18n.t('firmware.updaterule-table.edit.system')}
                                                denyUpdateLabel={i18n.t('firmware.updaterule-table.edit.denyupdate')}
                                                targetVersionLabel={i18n.t('firmware.updaterule-table.edit.targetversion')}
                                                cancelButtonLabel={i18n.t('firmware.updaterule-table.edit.button.cancel')}
                                                confirmButtonLabel={i18n.t('firmware.updaterule-table.edit.button.confirm')}
                                                okCallback={onEdit}
                                            />
                                        </td> */}
                                        {/* <td className='firmware-overview-clickable-cell'>
                                            <ModalButton
                                                as={<RiDeleteBin6Line />}
                                                size="lg"
                                                variant="outline-secondary"
                                                className="modal-button-invisible-outline"
                                                modalTitle={i18n.t('firmware.updaterule-table.delete.modal.title', { system: row.original.device })}
                                                modalBody={i18n.t('firmware.updaterule-table.delete.modal.body')}
                                                closeButtonLabel={i18n.t('firmware.updaterule-table.delete.button.cancel')}
                                                okButtonLabel={i18n.t('firmware.updaterule-table.delete.button.confirm')}
                                                okCallback={() => onDeleteUpdateRule(row.original.device)}
                                            />
                                        </td> */}
                                    </tr>
                                )
                            })}
                    </tbody>
                </Table>
            </BRow>
        </Container>
    )
}

export default FirmwareUpdateRuleTable;