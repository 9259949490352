import clsx from "clsx";
import { useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";

export interface RangeInputProps {
    title: string;
    buttonLabel: string;
    description?: string;
    advanced?: boolean;
    value: boolean;
    on: string;
    off: string;
    callback: (value: boolean) => any;
}

function BooleanInput({ title, buttonLabel, description, advanced, value, on, off, callback }: RangeInputProps) {

    const [snapshot, setSnapshot] = useState<boolean>(value)
    const [modified, setModified] = useState<boolean>(false)

    const trackChanges = (change: string) => {
        setModified((change === "on") !== value);
        setSnapshot(change === "on")
    }

    const submit = (value: boolean) => {
        callback(value);
        setModified(false);
    }

    return (
        <Container className={clsx("input-component-box", advanced && "input_component-advanced")}>
            <Row>
                <Col>
                    <span className="input-component-title">{title}</span>
                </Col>
            </Row>
            {description &&
                <Row>
                    <span className="input-component-description">{description}</span>
                </Row>
            }
            <Row>
                <Col>
                    <InputGroup>
                        <Form.Select
                            defaultValue={snapshot ? "on" : "off"}
                            onChange={(e: any) => trackChanges(e.target.value)}
                            className="form-control">
                            <option value={"on"}>{on}</option>
                            <option value={"off"}>{off}</option>
                        </Form.Select>
                        <Button variant="outline-secondary"
                            className={clsx(modified && "input-component-button-modified")}
                            onClick={() => submit(snapshot)}>
                            {buttonLabel}
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
        </Container>
    )
}

export default BooleanInput;