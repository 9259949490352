export const getHost = (): string => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_API_HOST as string;
    }
    return (window as any).REACT_APP_API_HOST;
}

export const getWsHost = (): string => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_WS_HOST as string;
    }
    return (window as any).REACT_APP_WS_HOST;
}