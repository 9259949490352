import React from 'react';
import i18n from "i18next";
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { GiHamburgerMenu } from "react-icons/gi";
import { FaArrowRightToBracket } from "react-icons/fa6";
import { useAuth0 } from "@auth0/auth0-react";

function TopBar(props) {
    const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const expand = props.expand;
    // console.log(user && user['https://tt.modual.ch/v1/roles']);
    return (
        <Navbar className="bg-body-tertiary" id="top-bar" expand={expand}>
            <Container>
                <Button id="top-bar-sidebar-button" variant="outline-secondary" onClick={() => props.toggleSidebar()}><GiHamburgerMenu /></Button>
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        {isAuthenticated && (
                            <NavDropdown title={user != null && user.email} id="basic-nav-dropdown">
                                <NavDropdown.Item href="#" onClick={() => logout()}>{i18n.t("topbar.button.logout")}</NavDropdown.Item>
                            </NavDropdown>
                        )}
                        {!isAuthenticated &&
                            <Button variant="link" className="topbarButton" onClick={() => loginWithRedirect()}><span><FaArrowRightToBracket /></span>&nbsp;{i18n.t("topbar.button.login")}</Button>
                        }
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default TopBar;
