import { Button, ButtonProps, Form, Modal } from "react-bootstrap";
import { useAppSelector } from "../redux/hooks";
import { selectSystems } from "../fleet/FleetSlice";
import { selectAllUpdateRules, selectAllFirmwareImages } from "./FirmwareSlice";
import { useState } from "react";
import { FirmwareType, FirmwareUpdateRule } from "./FirmwareTypes";
import { System } from "../fleet/FleetTypes";
import clsx from "clsx";

const typeRegex = new RegExp('^P-(?<type>[A-Z]{1}).*');

export interface AddUpdateRuleModalProps extends ButtonProps {
    as?: any;
    deviceId?: string;
    denyUpdate?: boolean;
    targetVersion?: string;
    buttonLabel?: string;
    buttonIcon?: any;
    title: string;
    systemLabel: string;
    denyUpdateLabel: string;
    targetVersionLabel: string;
    cancelButtonLabel: string;
    confirmButtonLabel: string;
    okCallback: (deviceId: string, denyUdpate: boolean, targetVersion: string) => void;
}

function AddOrEditUpdateRuleModal({ as, deviceId, denyUpdate, targetVersion, buttonLabel, buttonIcon, title, systemLabel, denyUpdateLabel, targetVersionLabel, cancelButtonLabel, confirmButtonLabel, okCallback, ...rest }: AddUpdateRuleModalProps) {
    const systems = useAppSelector(selectSystems);
    const data = useAppSelector(selectAllUpdateRules);
    const firmware = useAppSelector(selectAllFirmwareImages);

    const [show, setShow] = useState(false);

    const [newDeviceId, setNewDeviceId] = useState<string>(deviceId || "...");
    const [deviceIdType, setDeviceIdType] = useState<string>((deviceId && typeRegex.exec(deviceId)?.groups?.type) || "");
    const [newDenyUpdate, setNewDenyUpdate] = useState<boolean>(denyUpdate || false);
    const [newTargetVersion, setNewTargetVersion] = useState<string>(targetVersion || "");
    const [manualEntry, setManualEntry] = useState<boolean>(false);

    const isUpdateModal = deviceId !== undefined;

    const onUpdate = () => {
        okCallback(newDeviceId, newDenyUpdate, newTargetVersion);
    }

    const onAdd = () => {
        okCallback(newDeviceId, newDenyUpdate, newTargetVersion);
    }

    const updateRuleIds = data.reduce((acc: string[], row: FirmwareUpdateRule) => {
        acc.push(row.device);
        return acc;
    }, []);

    const deviceIds = systems.reduce((acc: string[], row: System) => {
        if (!updateRuleIds.includes(row.id)) {
            acc.push(row.id);
        }
        return acc;
    }, []);

    const updateModal = (
        <Modal show={show} onHide={() => setShow(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-button-body-text">
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>{systemLabel}</Form.Label>
                        <Form.Select defaultValue={newDeviceId} disabled>
                            <option key={-1} value={newDeviceId}>{newDeviceId}</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check type="checkbox" label={denyUpdateLabel} onChange={e => setNewDenyUpdate(e.target.checked)} checked={newDenyUpdate} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{targetVersionLabel}</Form.Label>
                        <Form.Select onChange={e => setNewTargetVersion(e.target.value)}
                            defaultValue={newTargetVersion}>
                            <option key={-1} value="">None</option>
                            {firmware.map((f, index) => {
                                return <option key={index} value={f.version}>{f.latest ? f.version + " (latest)" : f.version}</option>
                            })}
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    {cancelButtonLabel}
                </Button>
                <Button variant="danger" onClick={() => {
                    setShow(false);
                    onUpdate();
                }}>
                    {confirmButtonLabel}
                </Button>
            </Modal.Footer>
        </Modal >
    )

    const newModal = (
        <Modal show={show} onHide={() => setShow(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-button-body-text">
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>{systemLabel}</Form.Label>
                        <Form.Select defaultValue={newDeviceId} onChange={e => {
                            setNewDeviceId(e.target.value);
                            const deviceType = typeRegex.exec(e.target.value)?.groups?.type;
                            if (deviceType !== undefined && deviceType !== "")
                                setDeviceIdType(deviceType);
                            else {
                                setDeviceIdType("");
                            }
                        }} disabled={manualEntry}>
                            <option key={-1} value="...">...</option>
                            {deviceIds.map((id, index) => {
                                return <option key={index} value={id}>{id}</option>
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control type="text" value={newDeviceId} onChange={(e: any) => {
                            setNewDeviceId(e.target.value);
                            const deviceType = typeRegex.exec(e.target.value)?.groups?.type;
                            if (deviceType !== undefined && deviceType !== "")
                                setDeviceIdType(deviceType);
                            else {
                                setDeviceIdType("");
                            }
                            if (e.target.value === newDeviceId) {
                                setManualEntry(false);
                            } else {
                                setManualEntry(true);
                            }
                        }} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check type="checkbox" label={denyUpdateLabel} onChange={e => setNewDenyUpdate(e.target.checked)} checked={newDenyUpdate} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{targetVersionLabel}</Form.Label>
                        <Form.Select onChange={e => setNewTargetVersion(e.target.value)}
                            defaultValue={newTargetVersion}>
                            <option key={-1} value="">None</option>
                            {deviceIdType === "H" && firmware.map((f, index) => {
                                if (f.type === FirmwareType.Hexagon) {
                                    return <option key={index} value={f.version}>{f.latest ? f.version + " (latest)" : f.version}</option>
                                }
                                return null;
                            })}
                            {deviceIdType === "D" && firmware.map((f, index) => {
                                if (f.type === FirmwareType.Decagon) {
                                    return <option key={index} value={f.version}>{f.latest ? f.version + " (latest)" : f.version}</option>
                                }
                                return null;
                            })}
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    {cancelButtonLabel}
                </Button>
                <Button variant="danger" onClick={() => {
                    setShow(false);
                    onAdd();
                }}>
                    {confirmButtonLabel}
                </Button>
            </Modal.Footer>
        </Modal >
    )

    if (as) {
        return (
            <>
                <div onClick={() => setShow(true)}>
                    {as}
                </div>
                {isUpdateModal ? updateModal : newModal}
            </>
        );
    }

    return (
        <>
            <Button {...rest} onClick={() => setShow(true)}>
                {buttonIcon}
                <span className={clsx(buttonIcon && "modal-button-text-with-icon")}>{buttonLabel}</span>
            </Button>
            {isUpdateModal ? updateModal : newModal}
        </>
    )
}

export default AddOrEditUpdateRuleModal;
