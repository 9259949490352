import clsx from "clsx";
import { useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";

export interface OptionProps {
    key: string | number;
    value: string;
}

export interface RangeInputProps {
    title: string;
    buttonLabel: string;
    description?: string;
    advanced?: boolean;
    selected: string | number;
    options: OptionProps[];
    unit?: string;
    callback: (value: string | number) => any;
}

function SelectionInput({ title, buttonLabel, description, advanced, selected, options, unit, callback }: RangeInputProps) {

    const [snapshot, setSnapshot] = useState<string | number>(selected)
    const [modified, setModified] = useState<boolean>(false)

    const trackChanges = (change: string) => {
        // eslint-disable-next-line
        setModified(change != selected);
        setSnapshot(change)
    }

    const submit = (value: string | number) => {
        callback(value);
        setModified(false);
    }

    return (
        <Container className={clsx("input-component-box", advanced && "input_component-advanced")}>
            <Row>
                <Col>
                    <span className="input-component-title">{title}</span>
                </Col>
            </Row>
            {description &&
                <Row>
                    <span className="input-component-description">{description}</span>
                </Row>
            }
            <Row>
                <Col>
                    <InputGroup>
                        <Form.Select
                            defaultValue={snapshot}
                            onChange={(e: any) => trackChanges(e.target.value)}
                            className="form-control">
                            {options.map((option, index) => {
                                return <option key={index} value={option.key}>{option.value}</option>
                            })}
                        </Form.Select>
                        {unit &&
                            <InputGroup.Text>{unit}</InputGroup.Text>
                        }
                        <Button variant="outline-secondary"
                            className={clsx(modified && "input-component-button-modified")}
                            onClick={() => submit(snapshot)}>
                            {buttonLabel}
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
        </Container>
    )
}

export default SelectionInput;