import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../redux/store";


export interface Auth0State {
    backendLoginRequired: boolean
}

const initialState: Auth0State = {
    backendLoginRequired: false,
};

export const auth0Slice = createSlice({
    name: 'auth0',
    initialState,
    reducers: {
        backendAuthenticationFailed: (state) => {
            state.backendLoginRequired = true;
        },
        backendLoginWorked: (state) => {
            state.backendLoginRequired = false;
        }
    }
});

export const auth0Actions = auth0Slice.actions;

export const selectIsBackendLoginRequired = (state: RootState) => state.auth0.backendLoginRequired;

export default auth0Slice.reducer;
