import clsx from "clsx";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import i18n from "../../i18n";

export interface RangeInputProps {
    title: string;
    buttonLabel: string;
    description?: string;
    rangeDescription?: string;
    advanced?: boolean;
    value: number;
    min: number;
    max: number;
    unit?: string;
    callback: (value: number) => any;
}

function NumberRangeInput({ title, buttonLabel, description, rangeDescription, advanced, value, min, max, unit, callback }: RangeInputProps) {

    const [snapshot, setSnapshot] = useState<number>(value)
    const [modified, setModified] = useState<boolean>(false)
    const [validated, setValidated] = useState<boolean>(false)
    const [valid, setValid] = useState<boolean>(true)

    const trackChanges = (change: number) => {
        // eslint-disable-next-line
        setModified(change != value);
        setSnapshot(change)
        if (change >= min && change <= max) {
            setValid(true);
            setValidated(false);
        } else {
            setValid(false);
            setValidated(true);
        }
    }

    const submit = (value: number) => {
        if (valid) {
            callback(value);
            setModified(false);
        }
        callback(value);
        setModified(false);
    }

    useEffect(() => {
        setValidated(false);
        setValid(true);
    }, [value])

    return (
        <Container className={clsx("input-component-box", advanced && "input_component-advanced")}>
            <Row>
                <Col>
                    <span className="input-component-title">{title}</span>
                </Col>
            </Row>
            {description &&
                <Row>
                    <span className="input-component-description">{description}</span>
                </Row>
            }
            {rangeDescription &&
                <Row>
                    <Col>
                        <span className="input-component-valid-range-description">{rangeDescription}</span>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <InputGroup>
                        <Form.Control
                            type={"number"}
                            value={snapshot}
                            onChange={(e: any) => { setSnapshot(e.target.value); trackChanges(e.target.value); }}
                            className="form-control"
                            isInvalid={validated ? !valid : false}
                            isValid={validated ? valid : false}
                        />
                        {unit &&
                            <InputGroup.Text>{unit}</InputGroup.Text>
                        }
                        <Button variant="outline-secondary"
                            className={clsx(modified && "input-component-button-modified")}
                            onClick={() => submit(snapshot)}>
                            {buttonLabel}
                        </Button>
                        <Form.Control.Feedback type="invalid">{i18n.t("input.number-range.validation.limit", { min: min, max: max, unit: unit })}</Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
        </Container>
    )
}

export default NumberRangeInput;