import { useState, useEffect } from 'react';

import {
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import {
  rankItem,
} from '@tanstack/match-sorter-utils'

import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { Firmware } from './FirmwareTypes'
import i18n from "i18next";
import Moment from 'react-moment';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { Col, Row as BRow } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import DebouncedInput from '../component/DebouncedInput';
import { selectAllFirmwareImages, queryAllFirmware } from './FirmwareSlice';
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { setFirmwareLatest, setFirmwareMainstream, downloadFirmware, deleteFirmware } from './FirmwareApi';
import FileSaver from "file-saver";
import ModalButton from '../component/ModalButton';

const versionParser = (version: string) => {
  if (version === null) {
    return 'n/a';
  }
  var tokens = version.split(",");
  if (tokens.length >= 1) {
    return tokens[0];
  }
  return 'n/a';
}

const versionSort = (a: Row<Firmware>, b: Row<Firmware>, cId: string): number => {
  const versionA = versionParser(a.original.version).split(".");
  const versionB = versionParser(b.original.version).split(".");

  const vAMajor = Number(versionA[0]);
  const vAMinor = Number(versionA[1]);
  const vAPatch = Number(versionA[2]);

  const vBMajor = Number(versionB[0]);
  const vBMinor = Number(versionB[1]);
  const vBPatch = Number(versionB[2]);

  if (vAMajor > vBMajor) {
    return 1;
  }
  if (vAMajor < vBMajor) {
    return -1;
  }
  if (vAMinor > vBMinor) {
    return 1;
  }
  if (vAMinor < vBMinor) {
    return -1;
  }
  if (vAPatch > vBPatch) {
    return 1;
  }
  if (vAPatch < vBPatch) {
    return -1;
  }

  return 0;
}

const timeParser = (time: Date) => {
  if (time === undefined) {
    return 'n/a';
  }
  return <Moment date={time} format="YYYY-MM-DD HH:mm:ss" />
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value)
  addMeta({
    itemRank,
  })
  return itemRank.passed
}

const onDownloadFirmware = (type: string, version: string, fileName: string) => {
  downloadFirmware(type, version).then(data => {
    FileSaver.saveAs(data, fileName);
  }).catch(error => {
    // TODO: handle error
  });
}

function FirmwareOverviewTable(props: any) {
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectAllFirmwareImages);

  const [globalFilter, setGlobalFilter] = useState('')

  const onCheckboxClick = (id: number, field: string, checked: boolean) => {
    console.log("onCheckboxClick: " + field + " " + id + " " + checked);
    if (field === "latest") {
      setFirmwareLatest(id, checked).then(() => {
        console.log("setFirmwareLatest: " + id + " " + checked);
        dispatch(queryAllFirmware());
      }).catch(error => {
        // TODO: handle error
        console.error(error)
      });
    }
    if (field === "mainstream") {
      setFirmwareMainstream(id, checked).then(() => {
        console.log("setFirmwareMainstream: " + id + " " + checked);
        dispatch(queryAllFirmware());
      }).catch(error => {
        // TODO: handle error
        console.error(error)
      });
    }
  }

  const onDeleteFirmware = (id: number) => {
    deleteFirmware(id);
    console.log("deleteFirmware: " + id);
  }

  const createCheckbox = (id: number, field: string, checked: any) => {
    return (
      <Form.Check
        type="checkbox"
        onChange={value => onCheckboxClick(id, field, value.target.checked)}
        checked={checked}
      />
    )
  }

  const columnHelper = createColumnHelper<Firmware>()

  const columns = [
    columnHelper.accessor('type', {
      header: i18n.t('firmware.overview-table.header.type'),
      cell: info => info.getValue(),
      footer: info => info.column.id,
      sortDescFirst: false,
      enableMultiSort: true,
    }),
    columnHelper.accessor('version', {
      header: i18n.t('firmware.overview-table.header.version'),
      cell: info => versionParser(info.getValue()),
      footer: info => info.column.id,
      sortingFn: versionSort,
      sortDescFirst: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor('hash', {
      header: i18n.t('firmware.overview-table.header.hash'),
      cell: info => versionParser(info.getValue()),
      footer: info => info.column.id,
      sortDescFirst: false,
    }),
    columnHelper.accessor('latest', {
      header: i18n.t('firmware.overview-table.header.latest'),
      cell: info => createCheckbox(info.cell.row.original.id, "latest", info.cell.row.original.latest),
      footer: info => info.column.id,
      meta: {
        className: 'firmware-overview-clickable-cell',
      }
    }),
    columnHelper.accessor('mainstream', {
      header: i18n.t('firmware.overview-table.header.mainstream'),
      cell: info => createCheckbox(info.cell.row.original.id, "mainstream", info.cell.row.original.mainstream),
      footer: info => info.column.id,
      meta: {
        className: 'firmware-overview-clickable-cell',
      }
    }),
    columnHelper.accessor('timestamp', {
      header: i18n.t('firmware.overview-table.header.creation_date'),
      cell: info => timeParser(info.getValue()),
      footer: info => info.column.id,
    }),
    columnHelper.display({
      id: 'download',
      header: i18n.t('firmware.overview-table.header.download'),
      cell: info => <IoCloudDownloadOutline className="update-rule-clickable"
        onClick={() => onDownloadFirmware(info.cell.row.original.type, info.cell.row.original.version, info.cell.row.original.file)}
      />,
      footer: info => info.column.id,
      meta: {
        className: 'firmware-overview-clickable-cell',
      }
    }),
    columnHelper.display({
      id: 'delete',
      header: i18n.t('firmware.overview-table.header.delete'),
      cell: info => <ModalButton
        as={<RiDeleteBin6Line className="update-rule-clickable" />}
        size="lg"
        variant="outline-secondary"
        className="modal-button-invisible-outline"
        modalTitle={i18n.t('firmware.overview-table.delete.modal.title', { version: info.cell.row.original.version })}
        modalBody={i18n.t('firmware.overview-table.delete.modal.body', { type: info.cell.row.original.type })}
        closeButtonLabel={i18n.t('firmware.overview-table.delete.button.cancel')}
        okButtonLabel={i18n.t('firmware.overview-table.delete.button.confirm')}
        okCallback={() => onDeleteFirmware(info.cell.row.original.id)} />
    })
  ]

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'version',
      desc: true,
    }
  ]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
  })

  useEffect(() => {
    dispatch(queryAllFirmware());
  }, [dispatch])

  if (data === undefined) {
    return (
      <Container>
        <span>Aw Snap!</span>
      </Container>
    );
  }

  return (
    <Container {...props}>
      <BRow>
        <Col xs={4}>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={value => setGlobalFilter(String(value))}
            placeholder={i18n.t('firmware.overview-table.search.placeholder')}
          />
        </Col>
      </BRow>
      <BRow>
        <Table responsive id="firmware-overview-table">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.getCanSort() && ({
                            asc: <FaSortUp />,
                            desc: <FaSortDown />,
                          }[header.column.getIsSorted() as string] ?? <FaSort />)}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows
              .map(row => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td key={cell.id} {...cell.column.columnDef.meta}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </BRow>
    </Container>
  )
}

export default FirmwareOverviewTable;