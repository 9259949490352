import clsx from "clsx";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import i18n from "../../i18n";

export interface RangeInputProps {
    title: string;
    buttonLabel: string;
    description?: string;
    advanced?: boolean;
    value: string;
    validationRegex?: string;
    callback: (value: string) => any;
}

function TextInput({ title, buttonLabel, description, advanced, value, validationRegex, callback }: RangeInputProps) {

    const [snapshot, setSnapshot] = useState<string>(value)
    const [modified, setModified] = useState<boolean>(false)
    const [invalid, setInvalid] = useState<boolean>(false)

    const trackChanges = (change: string) => {
        // eslint-disable-next-line
        setModified(change != value);
        setSnapshot(change)
    }

    const submit = (value: string) => {
        if (invalid) {
            return;
        }
        callback(value);
        setModified(false);
    }

    useEffect(() => {
        setInvalid(!snapshot?.match(validationRegex || ".*"))
    }, [snapshot, validationRegex, setInvalid]);

    useEffect(() => {
        setInvalid(false);
        setModified(false);
    }, [value])

    return (
        <Container className={clsx("input-component-box", advanced && "input_component-advanced")}>
            <Row>
                <Col>
                    <span className="input-component-title">{title}</span>
                </Col>
            </Row>
            {description &&
                <Row>
                    <span className="input-component-description">{description}</span>
                </Row>
            }
            <Row>
                <Col>
                    <InputGroup>
                        <Form.Control
                            type={"text"}
                            value={snapshot}
                            onChange={(e: any) => { setSnapshot(e.target.value); trackChanges(e.target.value); }}
                            className="form-control"
                            isInvalid={invalid}
                            isValid={false}
                        />
                        <Button variant="outline-secondary"
                            className={clsx(modified && "input-component-button-modified")}
                            onClick={() => submit(snapshot)}>
                            {buttonLabel}
                        </Button>
                        <Form.Control.Feedback type="invalid">{i18n.t("input.text.validation.regex", { pattern: validationRegex })}</Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
        </Container>
    )
}

export default TextInput;