export interface FleetOverview {
    charge: number;
    discharge: number;
    rated_capacity: number;
    remaining_capacity: number;
    modules: number;
    cycles: number;
    charged: number;
    discharged: number;
    systems: number;
}

export interface System {
    id: string,
    health: string,
    version: string,
    last_seen: Date,
}

export interface SystemDetail {
    id: string,
    health: string,
    module_count: number,
    soc: number,
    min_soc: number,
    max_soc: number,
    dod: number,
    soh: number,
    voltage: number,
    delta_voltage: number,
    current: number,
    c_rate: number,
    temperature: number,
    capacity_cell: number,
    capacity_rated: number,
    capacity_remaining: number,
    cycles: number,
    charged: number,
    discharged: number,
    max_charge_current: number,
    max_discharge_current: number,
    version: string,
    valid_since: Date,
}

export enum HealthType {
    None = "SH_NONE",
    PreCharge = "SH_PRE_CHARGE",
    Operational = "SH_OPERATIONAL",
    Degraded = "SH_DEGRADED",
    OTA = "SH_OTA",
    Suspended = "SH_SUSPENT",
    Fault = "SH_FAULT",
    Restart = "SH_RESTART",
    Shutdown = "SH_SHUTDOWN",
}

export interface SocVoltage {
    min: number,
    max: number,
    value: number,
}
