import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Firmware, FirmwareUpdateRule } from "./FirmwareTypes";
import { fetchAllFirmware, fetchAllUpdateRules } from "./FirmwareApi";
import { RootState } from "../redux/store";


export interface FirmwareState {
    images: Firmware[],
    rules: FirmwareUpdateRule[];
}

const initialState: FirmwareState = {
    images: [] as Firmware[],
    rules: [] as FirmwareUpdateRule[],
};

export const queryAllFirmware = createAsyncThunk(
    "firmware/all",
    async () => {
        return await fetchAllFirmware();
    }
)

export const queryAllUpdateRules = createAsyncThunk(
    "firmware/rules",
    async () => {
        return await fetchAllUpdateRules();
    }
)

export const firmwareSlice = createSlice({
    name: 'firmware',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(queryAllFirmware.fulfilled, (state, action) => {
                state.images = action.payload;
            })
            .addCase(queryAllUpdateRules.fulfilled, (state, action) => {
                state.rules = action.payload;
            })
    }
});

export const selectAllFirmwareImages = (state: RootState) => state.firmware.images;
export const selectAllUpdateRules = (state: RootState) => state.firmware.rules;

export default firmwareSlice.reducer;
