import React, { useState } from 'react'
import Button, { ButtonProps } from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import clsx from 'clsx';

export interface ModalButtonProps extends ButtonProps {
    as?: any;
    modalTitle: string;
    modalBody: string;
    buttonLabel?: string;
    buttonIcon?: any;
    closeButtonLabel: string;
    okButtonLabel: string;
    okCallback: () => void;
}

function ModalButton({ as, modalTitle, modalBody, buttonLabel, buttonIcon, closeButtonLabel, okButtonLabel, okCallback, ...rest }: ModalButtonProps) {
    const [show, setShow] = useState(false);

    const modal = (
        <Modal show={show} onHide={() => setShow(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-button-body-text">
                {modalBody}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    {closeButtonLabel}
                </Button>
                <Button variant="danger" onClick={() => {
                    setShow(false);
                    okCallback();
                }}>
                    {okButtonLabel}
                </Button>
            </Modal.Footer>
        </Modal >
    )

    if (as) {
        return (
            <>
                <div onClick={() => setShow(true)}>
                    {as}
                </div>
                {modal}
            </>
        );
    }

    return (
        <>
            <Button {...rest} onClick={() => setShow(true)}>
                {buttonIcon}
                <span className={clsx(buttonIcon && "modal-button-text-with-icon")}>{buttonLabel}</span>
            </Button>

            {modal}
        </>
    )
}

export default ModalButton;