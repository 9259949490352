import { Container, Row, Col } from 'react-bootstrap';

export interface StatProps {
    title: string;
    value: number;
    unit: string;
}

function Stat(props: StatProps) {

    return (
        <Container className={'stat'}>
            <Row>
                <Col xs={10} className="stat-title">{props.title}</Col>
            </Row>
            <Row className="stat-value">
                <Col>{props.value}<span className="stat-unit">{props.unit}</span></Col>
            </Row>
        </Container >
    )
}

export default Stat;