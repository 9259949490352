import FirmwareUpdateRuleTable from "./FirmwareUpdateRuleTable";


function FirmwareUpdateRules() {
    return (
        <FirmwareUpdateRuleTable id="firmware-updaterules-overview-table" />
    )
}

export default FirmwareUpdateRules;
