import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../redux/store";

export interface SystemWstate {
    isEstablishingConnection: boolean;
    isConnected: boolean;
    isAuthenticated: boolean;
    hasError: boolean;
    target: string;
    message?: string;
    error?: string;
    configuration?: any;
    isConfigurationReceived: boolean;
    status?: any;
}

const initialState: SystemWstate = {
    isEstablishingConnection: false,
    isConnected: false,
    isAuthenticated: false,
    hasError: false,
    target: "",
    isConfigurationReceived: false,
};

export const systemWsSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        connect: (state, action: PayloadAction<string>) => {
            state.isConnected = false;
            state.isEstablishingConnection = true;
            state.hasError = false;
            state.isConfigurationReceived = false;
            state.isAuthenticated = false;
        },
        connectionEstablished: (state, action: PayloadAction<string>) => {
            state.isConnected = true;
            state.isEstablishingConnection = false;
            state.hasError = false;
            state.target = action.payload;
        },
        connectionAuthenticated: (state) => {
            state.isAuthenticated = true;
        },
        disconnect: (state) => {
            // no-op
        },
        stoppingConnection: (state) => {
            state.isConnected = false;
            state.isEstablishingConnection = false;
            state.hasError = false;
            state.target = "";
            state.message = "";
            state.configuration = undefined;
            state.isConfigurationReceived = false;
            state.isAuthenticated = false;
        },
        submitMessage: (state, action: PayloadAction<string>) => {
            // useful for message debugging
            // console.log("submitMessage: " + action.payload);
        },
        onError: (state, action: PayloadAction<string>) => {
            state.isConnected = false;
            state.isEstablishingConnection = false;
            state.isAuthenticated = false;
            state.hasError = true;
            state.target = "";
            state.message = "";
            state.configuration = undefined;
            state.error = action.payload;
        },
        onMessageReceived: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
        },
        onConfigurationReceived: (state, action: PayloadAction<string>) => {
            state.configuration = action.payload;
            state.isConfigurationReceived = true;
        },
        onStatusReceived: (state, action: PayloadAction<string>) => {
            state.status = action.payload;
        },
    }
});

export const systemWsActions = systemWsSlice.actions;

export const selectIsConnecting = (state: RootState) => state.systemWs.isEstablishingConnection;
export const selectIsConnected = (state: RootState) => state.systemWs.isConnected;
export const selectIsAuthenticated = (state: RootState) => state.systemWs.isAuthenticated;
export const selectHasError = (state: RootState) => state.systemWs.hasError;
export const selectConnectionTarget = (state: RootState) => state.systemWs.target;
export const selectMessage = (state: RootState) => state.systemWs.message;
export const selectIsConfigurationReceived = (state: RootState) => state.systemWs.isConfigurationReceived;
export const selectConfiguration = (state: RootState) => state.systemWs.configuration;
export const selectStatus = (state: RootState) => state.systemWs.status;
export const selectError = (state: RootState) => state.systemWs.error;

export default systemWsSlice.reducer;
