import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import i18n from "i18next";
import clsx from "clsx";
import { Button, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { fleetActions } from './FleetSlice';
import { fetchSystemDetail } from './FleetApi';
import { systemWsActions, selectIsConnected, selectMessage, selectConnectionTarget, selectIsConnecting, selectHasError, selectConfiguration, selectIsConfigurationReceived, selectIsAuthenticated } from '../connection/SystemWsSlice';
import { RiShutDownLine } from "react-icons/ri";
import { VscDebugDisconnect } from "react-icons/vsc";
import { Tooltip } from 'react-tooltip'
import { VscDebugRestart } from "react-icons/vsc";
import { RiRecycleLine } from "react-icons/ri";
import { RxUpdate } from "react-icons/rx";
import ModalButton from '../component/ModalButton';
import { queryAllFirmware, selectAllFirmwareImages } from '../firmware/FirmwareSlice';
import { HealthType } from './FleetTypes';
import { PiPlugsConnectedLight } from "react-icons/pi";
import { IconContext } from "react-icons";
import { TbPlugConnectedX } from "react-icons/tb";
import SelectionInput from '../component/input/SelectionInput';
import { SystemWsMessage } from '../connection/SystemWsTypes';
import Dod from './Dod';
import NumberRangeInput from '../component/input/NumberRangeInput';
import BooleanInput from '../component/input/BooleanInput';
import TextInput from '../component/input/TextInput';
import { FirmwareType } from '../firmware/FirmwareTypes';

function SystemInteraction(props) {
    const dispatch = useAppDispatch();
    const isConnecting = useAppSelector(selectIsConnecting);
    const isConnected = useAppSelector(selectIsConnected);
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const hasError = useAppSelector(selectHasError);
    const target = useAppSelector(selectConnectionTarget);
    const firmware = useAppSelector(selectAllFirmwareImages);
    const message = useAppSelector(selectMessage);
    const isConfigurationReceived = useAppSelector(selectIsConfigurationReceived);
    const configuration = useAppSelector(selectConfiguration);

    const [data, setData] = useState(null);
    const [isAdvancedMode, setIsAdvancedMode] = useState(false);
    const [requestContent, setRequestContent] = useState('');
    const [selectedFirmware, setSelectedFirmware] = useState(null);

    const clientId = props?.deviceId?.toUpperCase();
    const deviceType = props?.deviceType?.toUpperCase();

    useEffect(() => {
        fetchSystemDetail(clientId).then((data) => {
            setData(data);
        });
    }, [clientId]);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(systemWsActions.submitMessage(SystemWsMessage.CMD_READ_ALL_CONFIGURATIONS));
            dispatch(queryAllFirmware());
        }
    }, [dispatch, isAuthenticated]);

    const warmRestart = () => {
        dispatch(fleetActions.systemRestart());
        dispatch(systemWsActions.submitMessage(SystemWsMessage.CMD_WARM_RESTART));
    }

    const coldRestart = () => {
        dispatch(fleetActions.systemRestart());
        dispatch(systemWsActions.submitMessage(SystemWsMessage.CMD_COLD_RESTART));
    }

    const shutdown = () => {
        dispatch(fleetActions.systemShutdown());
        dispatch(systemWsActions.submitMessage(SystemWsMessage.CMD_SHUTDOWN));
    }

    const updateFirmware = () => {
        if (selectedFirmware) {
            if (deviceType === 'D') {
                dispatch(systemWsActions.submitMessage("{\"type\":2, \"cmd\":1, \"payload\":\"https://api.bctrl.ch/api/v1/firmware/download/decagon/" + selectedFirmware.version + "\"}"));
            }
            if (deviceType === 'H') {
                dispatch(systemWsActions.submitMessage("{\"type\":2, \"cmd\":1, \"payload\":\"https://api.bctrl.ch/api/v1/firmware/download/hexagon/" + selectedFirmware.version + "\"}"));
            }
        }
    }

    // I decided to use a switch statement to convert the value to the correct type
    // And yes it's ugly, but I simply don't care enough to make javascr(y)pt better.
    const convert = (key, value) => {
        switch (key) {
            case "hxgn_c_mcc":
            case "hxgn_c_mdc":
            case "hxgn_bat_mod":
            case "hxgn_mccv":
            case "hxgn_mdcv":
            case "hxgn_mchrg_cur":
            case "hxgn_mdchrg_cur":
            case "hxgn_mct":
            case "hxgn_op_mode":
            case "hxgn_soh":
                return parseInt(value);
            case "hxgn_cal_cur":
            case "hxgn_last_soc":
                return parseFloat(value);
            case "hxgn_cur_cal_en":
            case "log_stream":
            case "hxgn_raw_tele":
                return value ? "true" : "false"
            default:
                return value;
        }
    }

    const submitConfigurationChange = (key, value) => {
        dispatch(systemWsActions.submitMessage(JSON.stringify({ "type": 2, "cmd": 3, "key": key, "value": convert(key, value) })));
    }

    const submitDodValues = (minVoltage, minVoltageModified, maxVoltage, maxVoltageModified) => {
        if (minVoltageModified) {
            dispatch(systemWsActions.submitMessage(JSON.stringify({ "type": 2, "cmd": 3, "key": "hxgn_mdcv", "value": parseFloat(minVoltage) })));
        }
        if (maxVoltageModified) {
            dispatch(systemWsActions.submitMessage(JSON.stringify({ "type": 2, "cmd": 3, "key": "hxgn_mccv", "value": parseFloat(maxVoltage) })));
        }
    }

    if (!isConnected || target !== clientId) {
        return (
            <Container className="system-interaction" id="connect-to-system">
                <Row>
                    <Col>
                        <Button variant="outline-secondary"
                            size="lg"
                            className="modal-button-invisible-outline"
                            onClick={() => dispatch(systemWsActions.connect(clientId))}
                            data-tooltip-id="connect-to-system"
                            data-tooltip-content={i18n.t('fleet.system-interaction.button.connect.tooltip', { systemId: clientId })}>
                            <VscDebugDisconnect />
                            <span className="icon-button-text">{i18n.t('fleet.system-interaction.button.connect.label')}</span>
                        </Button>
                        <Tooltip id="connect-to-system" place="top" effect="solid" />
                    </Col>
                </Row>
                <Row>
                    <p id="system-interaction-connect-to-system-text">
                        {i18n.t('fleet.system-interaction.connect-to-system')}
                    </p>
                </Row>
            </Container >
        )
    }

    if (isConnecting || !isAuthenticated || !isConfigurationReceived) {
        return (
            <Container className="system-interaction" id="connect-to-system">
                <Row>
                    <Col>
                        <Spinner animation="border" role="warning" />
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <Container className="system-interaction" id="system-connected">
            <Row id="system-interaction-header">
                <Col className="system-interaction-align-center">
                    {isConnecting &&
                        <IconContext.Provider value={{ className: 'system-interaction-status-box-icon system-interaction-status-connecting' }}>
                            <TbPlugConnectedX /><span className="system-interaction-status-box system-interaction-status-connecting">{i18n.t('fleet.system-interaction.status.connecting')}</span>
                        </IconContext.Provider>
                    }
                    {isConnected &&
                        <IconContext.Provider value={{ className: 'system-interaction-status-box-icon system-interaction-status-connected' }}>
                            <PiPlugsConnectedLight /><span className="system-interaction-status-box system-interaction-status-connected">{i18n.t('fleet.system-interaction.status.connected')}</span>
                        </IconContext.Provider>
                    }
                    {hasError &&
                        <IconContext.Provider value={{ className: 'system-interaction-status-box-icon system-interaction-status-error' }}>
                            <TbPlugConnectedX /><span className="system-interaction-status-box system-interaction-status-error">{i18n.t('fleet.system-interaction.status.error')}</span>
                        </IconContext.Provider>
                    }
                </Col>
                <Col className="system-interaction-align-center">
                    <Button variant="outline-secondary"
                        size="lg"
                        className="modal-button-invisible-outline"
                        onClick={() => dispatch(systemWsActions.disconnect())}
                        data-tooltip-id="system-disconnect-tooltip"
                        data-tooltip-content={i18n.t('fleet.system-interaction.button.disconnect.tooltip', { systemId: clientId })}>
                        <VscDebugDisconnect />
                        <span className="icon-button-text">{i18n.t('fleet.system-interaction.button.disconnect.label')}</span>
                    </Button>
                    <Tooltip id="system-disconnect-tooltip" place="top" effect="solid" />
                </Col>
                <Col className="align-self-center system-interaction-align-center" onClick={() => setIsAdvancedMode(!isAdvancedMode)}>
                    <div id="system-interaction-advanced-mode-switch"
                        className={clsx(isAdvancedMode && "system-interaction-advanced-mode")}>
                        <Form.Check
                            type="switch"
                            label={i18n.t("fleet.system-interaction.advanced-mode")}
                            onChange={value => setIsAdvancedMode(value.target.checked)}
                            checked={isAdvancedMode}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Container className="system-interaction-content-box">
                    <Row>
                        <Col>
                            <span className="system-interaction-content-box-title">{i18n.t('fleet.system-interaction.box.lifecycle')}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="system-interaction-align-center">
                            <ModalButton
                                size="lg"
                                variant="outline-secondary"
                                className="modal-button-invisible-outline"
                                buttonLabel={i18n.t('fleet.system-interaction.button.restart.label')}
                                modalTitle={i18n.t('fleet.system-interaction.button.restart.modal.title')}
                                modalBody={i18n.t('fleet.system-interaction.button.restart.modal.body')}
                                buttonIcon={<VscDebugRestart />}
                                closeButtonLabel={i18n.t('fleet.system-interaction.button.restart.button.cancel')}
                                okButtonLabel={i18n.t('fleet.system-interaction.button.restart.button.confirm')}
                                okCallback={() => warmRestart()}
                                data-tooltip-id="system-warm-restart"
                                data-tooltip-content={i18n.t('fleet.system-interaction.button.restart.tooltip')}
                            />
                            <Tooltip id="system-warm-restart" place="top" effect="solid" />
                        </Col>
                        <Col className="system-interaction-align-center">
                            <ModalButton
                                size="lg"
                                variant="outline-secondary"
                                className="modal-button-invisible-outline"
                                buttonLabel={i18n.t('fleet.system-interaction.button.cold-restart.label')}
                                modalTitle={i18n.t('fleet.system-interaction.button.cold-restart.modal.title')}
                                modalBody={i18n.t('fleet.system-interaction.button.cold-restart.modal.body')}
                                buttonIcon={<RiRecycleLine />}
                                closeButtonLabel={i18n.t('fleet.system-interaction.button.cold-restart.button.cancel')}
                                okButtonLabel={i18n.t('fleet.system-interaction.button.cold-restart.button.confirm')}
                                okCallback={() => coldRestart()}
                                data-tooltip-id="system-warm-restart"
                                data-tooltip-content={i18n.t('fleet.system-interaction.button.cold-restart.tooltip')}
                            />
                            <Tooltip id="system-cold-restart" place="top" effect="solid" />
                        </Col>
                        {isAdvancedMode &&
                            <Col className="system-interaction-align-center">
                                <ModalButton
                                    size="lg"
                                    variant="outline-secondary"
                                    className="modal-button-invisible-outline system-interaction-advanced-mode"
                                    buttonLabel={i18n.t('fleet.system-interaction.button.shutdown.label')}
                                    modalTitle={i18n.t('fleet.system-interaction.button.shutdown.modal.title')}
                                    modalBody={i18n.t('fleet.system-interaction.button.shutdown.modal.body')}
                                    buttonIcon={<RiShutDownLine />}
                                    closeButtonLabel={i18n.t('fleet.system-interaction.button.shutdown.button.cancel')}
                                    okButtonLabel={i18n.t('fleet.system-interaction.button.shutdown.button.confirm')}
                                    okCallback={() => shutdown()}
                                    data-tooltip-id="system-warm-restart"
                                    data-tooltip-content={i18n.t('fleet.system-interaction.button.shutdown.tooltip')}
                                />
                                <Tooltip id="system-shutdown" place="top" effect="solid" />
                            </Col>
                        }
                    </Row>
                </Container>
            </Row>
            {
                isAdvancedMode &&
                <Row>
                    <Container className="system-interaction-content-box input_component-advanced">
                        <Row>
                            <Col>
                                <span className="system-interaction-content-box-title">{i18n.t('fleet.system-interaction.box.command_line')}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <Form.Control
                                        placeholder={i18n.t('fleet.system-interaction.input.command.placeholder')}
                                        value={requestContent}
                                        onChange={e => setRequestContent(e.target.value)}
                                    />
                                    <Button variant="outline-secondary"
                                        onClick={() => dispatch(systemWsActions.submitMessage(requestContent))}>
                                        {i18n.t('fleet.system-interaction.button.send.label')}
                                    </Button>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <Form.Select
                                        defaultValue=""
                                        onChange={e => setRequestContent(e.target.value)}>
                                        <option value="">Select a command</option>
                                        <option value='{ "type": 2, "cmd": 2 }'>{i18n.t("fleet.system-interaction.input.predefined.read-environment")}</option>
                                        <option value={SystemWsMessage.CMD_READ_ALL_CONFIGURATIONS}>{i18n.t("fleet.system-interaction.input.predefined.read-all-config")}</option>
                                        <option value='{ "type": 2, "cmd": 4, "key": "INSERT_KEY_HERE" }'>{i18n.t("fleet.system-interaction.input.predefined.read-specific-config-item")}</option>
                                        <option value='{ "type": 2, "cmd": 3, "key": "INSERT_KEY_HERE", "value": INSERT_VALUE_HERE }'>{i18n.t("fleet.system-interaction.input.predefined.write-specific-config-item")}</option>
                                        {deviceType === 'H' &&
                                            <>
                                                <option value='{ "type": 2, "cmd": 1, "payload": "https://api.bctrl.ch/api/v1/firmware/download/hexagon/latest"}'>{i18n.t("fleet.system-interaction.input.predefined.update-to-latest-firmware")}</option>
                                                <option value='{ "type": 2, "cmd": 1, "payload": "https://api.bctrl.ch/api/v1/firmware/download/hexagon/INSERT_VERSION_HERE"}'>{i18n.t("fleet.system-interaction.input.predefined.update-to-specific-firmware")}</option>
                                            </>
                                        }
                                        {deviceType === 'D' &&
                                            <>
                                                <option value='{ "type": 2, "cmd": 1, "payload": "https://api.bctrl.ch/api/v1/firmware/download/decagon/latest"}'>{i18n.t("fleet.system-interaction.input.predefined.update-to-latest-firmware")}</option>
                                                <option value='{ "type": 2, "cmd": 1, "payload": "https://api.bctrl.ch/api/v1/firmware/download/decagon/INSERT_VERSION_HERE"}'>{i18n.t("fleet.system-interaction.input.predefined.update-to-specific-firmware")}</option>
                                            </>
                                        }
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control as="textarea" rows={10} value={message} readOnly />
                            </Col>
                        </Row>
                    </Container>
                </Row>
            }
            {firmware &&
                <Row>
                    <Container className="system-interaction-content-box">
                        <Row>
                            <Col>
                                <span className="system-interaction-content-box-title">{i18n.t('fleet.system-interaction.box.firmware')}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <Form.Select
                                        disabled={data?.health === HealthType.OTA}
                                        onChange={e => setSelectedFirmware(firmware?.find((item) => {
                                            if (deviceType === "H" && item.type === FirmwareType.Hexagon && item.version === e.target.value) {
                                                return e.target.value
                                            }
                                            if (deviceType === "D" && item.type === FirmwareType.Decagon && item.version === e.target.value) {
                                                return e.target.value
                                            }
                                            return null
                                        }))}>
                                        defaultValue={selectedFirmware?.version}
                                        {firmware?.reduce((dict, item) => {
                                            if (deviceType === "H" && item.type === FirmwareType.Hexagon) {
                                                dict.push(item)
                                            }
                                            if (deviceType === "D" && item.type === FirmwareType.Decagon) {
                                                dict.push(item)
                                            }
                                            return dict
                                        }, [])?.map((f, index) => {
                                            if (selectedFirmware === null && f.latest) {
                                                setSelectedFirmware(f);
                                            }
                                            if (isAdvancedMode) {
                                                return <option key={index} value={f.version}>{f.latest ? f.version + " (latest)" : f.version}</option>
                                            }
                                            if (f.mainstream) {
                                                return <option key={index} value={f.version}>{f.latest ? f.version + " (latest)" : f.version}</option>
                                            }
                                            return null;
                                        })}
                                    </Form.Select>
                                    <ModalButton
                                        size="lg"
                                        variant="outline-secondary"
                                        className="modal-button-invisible-outline"
                                        disabled={data?.health === HealthType.OTA}
                                        buttonLabel={i18n.t('fleet.system-interaction.button.update.label')}
                                        modalTitle={i18n.t('fleet.system-interaction.button.update.modal.title', { version: selectedFirmware?.version })}
                                        modalBody={i18n.t('fleet.system-interaction.button.update.modal.body', { version: selectedFirmware?.version })}
                                        buttonIcon={<RxUpdate />}
                                        closeButtonLabel="Cancel"
                                        okButtonLabel="Restart"
                                        okCallback={() => updateFirmware()}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            }
            {deviceType === 'D' && configuration &&
                <Row>
                    <Container className="system-interaction-content-box">
                        <Row>
                            <Col>
                                <span className="system-interaction-content-box-title">{i18n.t('fleet.system-interaction.box.configuration')}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput
                                    title={i18n.t("fleet.system-interaction.configuration.items.vrm-id.title")}
                                    description={i18n.t("fleet.system-interaction.configuration.items.vrm-id.description")}
                                    buttonLabel={i18n.t("fleet.system-interaction.configuration.button.save")}
                                    advanced={true}
                                    value={configuration.config.dec_gx_serial.value}
                                    validationRegex='^[a-f0-9]{12}$'
                                    callback={value => submitConfigurationChange("dec_gx_serial", value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput
                                    title={i18n.t("fleet.system-interaction.configuration.items.smartme-id.title")}
                                    description={i18n.t("fleet.system-interaction.configuration.items.smartme-id.description")}
                                    buttonLabel={i18n.t("fleet.system-interaction.configuration.button.save")}
                                    advanced={true}
                                    value={configuration.config.dec_sme_serial.value}
                                    validationRegex='(^$|^[0-9]{8}$)'
                                    callback={value => submitConfigurationChange("dec_sme_serial", value)} />
                            </Col>
                        </Row>
                    </Container>
                </Row>
            }
            {
                deviceType === 'H' && configuration &&
                <Row>
                    <Container className="system-interaction-content-box">
                        <Row>
                            <Col>
                                <span className="system-interaction-content-box-title">{i18n.t('fleet.system-interaction.box.configuration')}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SelectionInput
                                    title={i18n.t("fleet.system-interaction.configuration.items.module-count.title")}
                                    description={i18n.t("fleet.system-interaction.configuration.items.module-count.description")}
                                    buttonLabel={i18n.t("fleet.system-interaction.configuration.button.save")}
                                    selected={configuration.config.hxgn_bat_mod.value}
                                    options={[
                                        { "key": 1, "value": "1" },
                                        { "key": 2, "value": "2" },
                                        { "key": 3, "value": "3" },
                                        { "key": 4, "value": "4" },
                                        { "key": 5, "value": "5" },
                                        { "key": 6, "value": "6" },
                                        { "key": 7, "value": "7" },
                                        { "key": 8, "value": "8" },
                                    ]}
                                    callback={value => submitConfigurationChange("hxgn_bat_mod", value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <NumberRangeInput
                                    title={i18n.t("fleet.system-interaction.configuration.items.max-charge-current.title")}
                                    description={i18n.t("fleet.system-interaction.configuration.items.max-charge-current.description")}
                                    rangeDescription={i18n.t("fleet.system-interaction.configuration.items.max-charge-current.range-description", { min: 0, max: 100 })}
                                    buttonLabel={i18n.t("fleet.system-interaction.configuration.button.save")}
                                    min={0}
                                    max={100}
                                    value={configuration.config.hxgn_mchrg_cur.value}
                                    unit={i18n.t("fleet.system-interaction.configuration.items.max-charge-current.unit")}
                                    callback={value => submitConfigurationChange("hxgn_mchrg_cur", value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <NumberRangeInput
                                    title={i18n.t("fleet.system-interaction.configuration.items.max-discharge-current.title")}
                                    description={i18n.t("fleet.system-interaction.configuration.items.max-discharge-current.description")}
                                    rangeDescription={i18n.t("fleet.system-interaction.configuration.items.max-discharge-current.range-description", { min: 0, max: 100 })}
                                    buttonLabel={i18n.t("fleet.system-interaction.configuration.button.save")}
                                    min={0}
                                    max={100}
                                    value={configuration.config.hxgn_mdchrg_cur.value}
                                    unit={i18n.t("fleet.system-interaction.configuration.items.max-discharge-current.unit")}
                                    callback={value => submitConfigurationChange("hxgn_mdchrg_cur", value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <NumberRangeInput
                                    title={i18n.t("fleet.system-interaction.configuration.items.max-current-tolerance.title")}
                                    description={i18n.t("fleet.system-interaction.configuration.items.max-current-tolerance.description")}
                                    rangeDescription={i18n.t("fleet.system-interaction.configuration.items.max-current-tolerance.range-description", { min: 0, max: 20 })}
                                    buttonLabel={i18n.t("fleet.system-interaction.configuration.button.save")}
                                    min={0}
                                    max={20}
                                    value={configuration.config.hxgn_mct.value}
                                    unit={i18n.t("fleet.system-interaction.configuration.items.max-current-tolerance.unit")}
                                    callback={value => submitConfigurationChange("hxgn_mct", value)}
                                />
                            </Col>
                        </Row>
                        {configuration.config.hxgn_soh &&
                            <Row>
                                <Col>
                                    <NumberRangeInput
                                        title={i18n.t("fleet.system-interaction.configuration.items.soh.title")}
                                        description={i18n.t("fleet.system-interaction.configuration.items.soh.description")}
                                        rangeDescription={i18n.t("fleet.system-interaction.configuration.items.soh.range-description", { min: 0, max: 100 })}
                                        buttonLabel={i18n.t("fleet.system-interaction.configuration.button.save")}
                                        min={0}
                                        max={100}
                                        value={configuration.config.hxgn_soh.value}
                                        unit={i18n.t("fleet.system-interaction.configuration.items.soh.unit")}
                                        callback={value => submitConfigurationChange("hxgn_soh", value)}
                                    />
                                </Col>
                            </Row>
                        }
                        {configuration.config.hxgn_cur_cal_en &&
                            <Row>
                                <Col>
                                    <BooleanInput
                                        title={i18n.t("fleet.system-interaction.configuration.items.current-calibration.title")}
                                        description={i18n.t("fleet.system-interaction.configuration.items.current-calibration.description")}
                                        buttonLabel={i18n.t("fleet.system-interaction.configuration.button.save")}
                                        on={i18n.t("fleet.system-interaction.configuration.items.current-calibration.on")}
                                        off={i18n.t("fleet.system-interaction.configuration.items.current-calibration.off")}
                                        value={configuration.config.hxgn_cur_cal_en.value}
                                        callback={value => submitConfigurationChange("hxgn_cur_cal_en", value)} />
                                </Col>
                            </Row>
                        }
                        {configuration.config.hxgn_mccv && configuration.config.hxgn_mdcv &&
                            <Row>
                                <Col>
                                    <Dod
                                        systemId={data?.id}
                                        soc={data?.soc}
                                        mdcv={configuration.config.hxgn_mdcv?.value}
                                        mccv={configuration.config.hxgn_mccv?.value}
                                        callback={(minVoltage, minVoltageModified, maxVoltage, maxVoltageModified) => submitDodValues(minVoltage, minVoltageModified, maxVoltage, maxVoltageModified)} />
                                </Col>
                            </Row>
                        }
                        {isAdvancedMode &&
                            <>
                                <Row>
                                    <Col>
                                        <SelectionInput
                                            title={i18n.t("fleet.system-interaction.configuration.items.op-mode.title")}
                                            description={i18n.t("fleet.system-interaction.configuration.items.op-mode.description")}
                                            buttonLabel={i18n.t("fleet.system-interaction.configuration.button.save")}
                                            advanced={true}
                                            selected={configuration.config.hxgn_op_mode.value}
                                            options={[
                                                { "key": 1, "value": i18n.t("fleet.system-interaction.configuration.items.op-mode.options.1") },
                                                { "key": 2, "value": i18n.t("fleet.system-interaction.configuration.items.op-mode.options.2") },
                                                { "key": 3, "value": i18n.t("fleet.system-interaction.configuration.items.op-mode.options.3") }
                                            ]}
                                            callback={value => submitConfigurationChange("hxgn_op_mode", value)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextInput
                                            title={i18n.t("fleet.system-interaction.configuration.items.master-id.title")}
                                            description={i18n.t("fleet.system-interaction.configuration.items.master-id.description")}
                                            buttonLabel={i18n.t("fleet.system-interaction.configuration.button.save")}
                                            advanced={true}
                                            value={configuration.config.hxgn_c_mid.value}
                                            validationRegex='^P-H[0-9A-F]{3}-[0-9]{6}$'
                                            callback={value => submitConfigurationChange("hxgn_c_mid", value)} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <NumberRangeInput
                                            title={i18n.t("fleet.system-interaction.configuration.items.cluster-charge-current.title")}
                                            description={i18n.t("fleet.system-interaction.configuration.items.cluster-charge-current.description")}
                                            rangeDescription={i18n.t("fleet.system-interaction.configuration.items.cluster-charge-current.range-description", { min: 0, max: 500 })}
                                            buttonLabel={i18n.t("fleet.system-interaction.configuration.button.save")}
                                            min={0}
                                            max={500}
                                            advanced={true}
                                            value={configuration.config.hxgn_c_mcc.value}
                                            unit={i18n.t("fleet.system-interaction.configuration.items.cluster-charge-current.unit")}
                                            callback={value => submitConfigurationChange("hxgn_c_mcc", value)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <NumberRangeInput
                                            title={i18n.t("fleet.system-interaction.configuration.items.cluster-discharge-current.title")}
                                            description={i18n.t("fleet.system-interaction.configuration.items.cluster-discharge-current.description")}
                                            rangeDescription={i18n.t("fleet.system-interaction.configuration.items.cluster-discharge-current.range-description", { min: 0, max: 500 })}
                                            buttonLabel={i18n.t("fleet.system-interaction.configuration.button.save")}
                                            min={0}
                                            max={500}
                                            advanced={true}
                                            value={configuration.config.hxgn_c_mdc.value}
                                            unit={i18n.t("fleet.system-interaction.configuration.items.cluster-discharge-current.unit")}
                                            callback={value => submitConfigurationChange("hxgn_c_mdc", value)}
                                        />
                                    </Col>
                                </Row>
                                {configuration.config.log_stream &&
                                    <Row>
                                        <Col>
                                            <BooleanInput
                                                title={i18n.t("fleet.system-interaction.configuration.items.log-streaming.title")}
                                                description={i18n.t("fleet.system-interaction.configuration.items.log-streaming.description")}
                                                buttonLabel={i18n.t("fleet.system-interaction.configuration.button.save")}
                                                on={i18n.t("fleet.system-interaction.configuration.items.log-streaming.on")}
                                                off={i18n.t("fleet.system-interaction.configuration.items.log-streaming.off")}
                                                advanced={true}
                                                value={configuration.config.log_stream.value}
                                                callback={value => submitConfigurationChange("log_stream", value)} />
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col>
                                        <BooleanInput
                                            title={i18n.t("fleet.system-interaction.configuration.items.raw-telemetry.title")}
                                            description={i18n.t("fleet.system-interaction.configuration.items.raw-telemetry.description")}
                                            buttonLabel={i18n.t("fleet.system-interaction.configuration.button.save")}
                                            on={i18n.t("fleet.system-interaction.configuration.items.raw-telemetry.on")}
                                            off={i18n.t("fleet.system-interaction.configuration.items.raw-telemetry.off")}
                                            advanced={true}
                                            value={configuration.config.hxgn_raw_tele.value}
                                            callback={value => submitConfigurationChange("hxgn_raw_tele", value)} />
                                    </Col>
                                </Row>
                            </>
                        }
                    </Container>
                </Row >
            }
        </Container >
    )
}

export default SystemInteraction;
