import { useEffect, useState } from 'react';
import i18n from "i18next";
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { fetchSystemDetail } from './FleetApi';
import { selectSystemRestartInProgress } from "./FleetSlice";
import Stat from '../component/Stat';
import HealthStat from '../component/HealthStat';
import Moment from 'react-moment';
import useInterval from '../hooks/interval';
import { IconContext } from "react-icons";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { VscGraph } from "react-icons/vsc";
import { Tooltip } from 'react-tooltip'
import SystemInteraction from './SystemInteraction';
import { selectStatus } from '../connection/SystemWsSlice';
import { HealthType } from './FleetTypes';

const typeRegex = new RegExp('^P-(?<type>[A-Z]{1}).*');

const createGrafanaLink = (id, lastSeen) => {
    const now = new Date();
    const from = lastSeen === undefined ? "now-3h" : new Date(lastSeen).getTime() - (60 * 60 * 1000);
    const to = lastSeen === undefined ? "now" : new Date(lastSeen).getTime() + (60 * 60 * 1000);
    return "https://twiceenergy.grafana.net/d/fc4cf3b6-d7e2-49d4-9f9e-572bceca207d/device-beta?orgId=1&var-device=" + id?.toUpperCase() + "&from=" + from + "&to=" + (to > now.getTime() ? "now" : to);
}

const versionParser = (version) => {
    if (version === null && version === undefined) {
        return 'n/a';
    }
    var tokens = version?.split(",");
    if (tokens?.length >= 2) {
        return tokens[0] + ", " + tokens[1];
    }
    return 'n/a';
}

function System(props) {
    const [queryParameters] = useSearchParams();
    const deviceId = queryParameters.get("id");
    const deviceType = typeRegex.exec(deviceId)?.groups?.type;
    const status = useAppSelector(selectStatus);
    const systemRestartInProgress = useAppSelector(selectSystemRestartInProgress);
    const systemShutdownInProgress = useAppSelector(selectSystemRestartInProgress);

    const [data, setData] = useState(null);

    useInterval(() => {
        fetchSystemDetail(deviceId).then((data) => {
            setData(data);
        });
    }, 2000);

    useEffect(() => {
        fetchSystemDetail(deviceId).then((data) => {
            setData(data);
        });
    }, [deviceId])

    const health = systemRestartInProgress ? HealthType.Restart : systemShutdownInProgress ? HealthType.Shutdown : (status?.state || data?.health);
    const version = systemRestartInProgress ? 'n/a' : (status?.version || data?.version);

    return (
        <Container className="system">
            <Row id="system-link-row">
                <Col id="system-backlink" xs={8}>
                    <Link to="/fleet/dashboard"
                        data-tooltip-id="back-link-tooltip"
                        data-tooltip-content={i18n.t('fleet.system.tooltip.back-link')}>
                        <div id="system-backlink-content">
                            <div>
                                <IconContext.Provider value={{ className: 'navigation-back-button' }}>
                                    <IoArrowBackCircleOutline />
                                </IconContext.Provider>
                            </div>
                            <div>
                                <span id="system-backlink-text">{deviceId}</span>
                            </div>
                        </div>
                    </Link>
                    <Tooltip id="back-link-tooltip" place="bottom" effect="solid" />
                </Col>
                {deviceType === 'H' &&
                    <Col id="grafana-link">
                        <IconContext.Provider value={{ className: 'system-grafana-button' }}>
                            <VscGraph
                                onClick={() => window.open(createGrafanaLink(deviceId, data?.valid_since), '_blank')}
                                data-tooltip-id="grafana-link-tooltip"
                                data-tooltip-content={i18n.t('fleet.system.tooltip.grafana-link')}
                            />
                        </IconContext.Provider>
                        <Tooltip id="grafana-link-tooltip" place="bottom" effect="solid" />
                    </Col>
                }
            </Row>
            <Row>
                <Col>
                    <HealthStat title={i18n.t('fleet.system.box.health')} value={health} unit="" />
                </Col>
                <Col>
                    <Stat title={i18n.t('fleet.system.box.version')} value="" unit={versionParser(version)} />
                </Col>
                <Col>
                    <Stat title={i18n.t('fleet.system.box.last_seen')} value="" unit={<Moment date={data?.valid_since} format="YYYY-MM-DD HH:mm:ss" />} />
                </Col>
            </Row>
            {deviceType === 'H' &&
                <Row>
                    <Col>
                        <Stat title={i18n.t('fleet.system.box.soc')} value={(data?.soc / 100)?.toFixed(2)} unit="%" />
                    </Col>
                    <Col>
                        <Stat title={i18n.t('fleet.system.box.voltage')} value={(data?.voltage)?.toFixed(2)} unit="V" />
                    </Col>
                    <Col>
                        <Stat title={i18n.t('fleet.system.box.current')} value={(data?.current)?.toFixed(2)} unit="A" />
                    </Col>
                    <Col>
                        <Stat title={i18n.t('fleet.system.box.temperature')} value={(data?.temperature)?.toFixed(2)} unit="C" />
                    </Col>
                </Row>
            }
            <Row>
                <SystemInteraction deviceId={deviceId} deviceType={deviceType} />
            </Row>
        </Container>
    )
}

export default System;