import clsx from 'clsx';
import { Container, Row, Col } from 'react-bootstrap';
import { HealthType } from '../fleet/FleetTypes';

export interface HealthProps {
    title: string;
    value: string;
    unit: string;
}

// this is not translated since it has to correspond to the values in the backend for easy search access 
// within the table.
const healthParser = (health: string) => {
    // Hexagon
    switch (health) {
        case HealthType.None:
            return 'n/a';
        case HealthType.PreCharge:
            return 'Pre-charge';
        case HealthType.Operational:
            return 'Operational';
        case HealthType.Degraded:
            return 'Degraded';
        case HealthType.OTA:
            return 'Update in Progress';
        case HealthType.Suspended:
            return 'Suspended';
        case HealthType.Fault:
            return 'Fault';
        case HealthType.Restart:
            return 'Restarting';
        case HealthType.Shutdown:
            return 'Shutdown';
        default:
        // no-op
    }
    // decagon
    switch (health) {
        case 'ERROR':
            return 'Fault';
        case 'OK':
            return 'Operational';
        case 'OTA':
            return 'Update in Progress';
        case 'SHUTDOWN':
            return 'Shutdown';
        case 'OFFLINE':
            return 'Shutdown';
        default:
        // no-op
    }
    return 'Unknown';
}

function HealthStat(props: HealthProps) {

    const health = healthParser(props.value);

    return (
        <Container className={clsx('stat', 'stat-health-' + props.value?.toLocaleLowerCase())}>
            <Row>
                <Col xs={10} className="stat-title">{props.title}</Col>
            </Row>
            <Row className="stat-value">
                <Col>{health}<span className="stat-unit">{props.unit}</span></Col>
            </Row>
        </Container >
    )
}

export default HealthStat;