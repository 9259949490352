import { useEffect } from 'react';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { ProtectedRoute, useAuth0Token } from './auth/auth0-helper';
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import Root from './theme/Root';
import Error from './theme/Error';
import Home from './Home';
import FleetDashboard from './fleet/FleetDashboard';

import 'bootstrap';
import "./styles/App.scss";
import System from './fleet/System';
import { selectIsBackendLoginRequired } from './auth/Auth0Slice';
import FirmwareDashboard from './firmware/FirmwareDashboard';
import FirmwareUpdateRules from './firmware/FirmwareUpdateRules';

const router = createHashRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Home />,
        errorElement: <Error />,
      },
      {
        path: "/fleet/dashboard",
        element: <ProtectedRoute component={FleetDashboard} />,
        // element: <FleetDashboard />,
        errorElement: <Error />,
      },
      {
        path: "/fleet/system",
        element: <ProtectedRoute component={System} />,
        // element: <System />,
        errorElement: <Error />,
      },
      {
        path: "firmware/dashboard",
        element: <ProtectedRoute component={FirmwareDashboard} role={['firmware_mgmt']} />,
        // element: <FirmwareDashboard />,
        errorElement: <Error />,
      },
      {
        path: "firmware/updaterule",
        element: <ProtectedRoute component={FirmwareUpdateRules} role={['firmware_mgmt']} />,
        // element: <FirmwareUpdateRules />,
        errorElement: <Error />,
      }
      // {
      //   path: "admin",
      //   element: <ProtectedRoute component={Dashboard} role={['ADMIN']} />,
      //   errorElement: <Error />,
      // }
    ]
  }
]);

function App() {

  const { loginWithRedirect } = useAuth0();
  const dispatch = useAppDispatch();
  const isBackendLoginRequired = useAppSelector(selectIsBackendLoginRequired);

  useAuth0Token();

  useEffect(() => {
    if (isBackendLoginRequired) {
      loginWithRedirect();
    }
  }, [dispatch, isBackendLoginRequired, loginWithRedirect])


  return (
    <RouterProvider router={router} />
  );
}

export default App;
