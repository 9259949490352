import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

import en from "./locales/en.json";
import de from "./locales/de.json";
import fr from "./locales/fr.json";

export const AVAILABLE_LANGUAGES = ['en', 'de', 'fr']
const DEFAULT_LANGUAGE = 'en'

const DETECTOR_OPTIONS = {
    order: ['localStorage', 'cookie', 'navigator'],
    caches: ['localStorage', 'cookie'],
    lookupLocalStorage: 'i18nextLng',
    lookupCookie: 'i18next',
    excludeCacheFor: ['cimode'],
    checkWhitelist: true,
    checkForSimilarInWhitelist: true
}

const I18N_OPTIONS = {
    debug: process.env.NODE_ENV === 'development',
    detection: DETECTOR_OPTIONS,
    supportedLngs: AVAILABLE_LANGUAGES,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
        escapeValue: false
    },
    resources: {
        en: {
            common: en
        },
        de: {
            common: de
        },
        fr: {
            common: fr
        }
    },
    ns: ["glossary", "common"],
    defaultNS: "common",
    fallbackNS: ["glossary", "common"],
    load: "all"
}

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init(I18N_OPTIONS);

export default i18n;