export interface Firmware {
    id: number;
    type: FirmwareType;
    version: string;
    hash: string;
    file: string;
    mainstream: boolean;
    latest: boolean;
    timestamp: Date;
}

export enum FirmwareType {
    Hexagon = "hexagon",
    Decagon = "decagon",
}

export interface FirmwareUpdateRule {
    device: string;
    deny_update: boolean;
    target_version: string;
}
