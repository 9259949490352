import FirmwareOverviewTable from "./FirmwareOverviewTable";


function FirmwareDashboard() {
    return (
        <FirmwareOverviewTable id="firmware-overview-table" />
    )
}

export default FirmwareDashboard;
